import { Route } from "vue-router";
import store from "@/store";
import { allowToPath } from "@/helpers/generalHelpers";

export const maybeRedirectOnboarding = async (
  toRoute: Route,
  fromRoute: Route,
  next: any
): Promise<void> => {
  // If already going to onboarding, continue
  if (toRoute.path === "/onboarding") {
    return next();
  }

  if (allowToPath(toRoute.path)) {
    return next();
  }
  // if the non-admin user is logged in, and hasn't gone through the onboarding process, redirect to onboarding
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  const isAdmin = store.getters["auth/isCurrentUserAdmin"];
  const user = store.getters["auth/getCurrentUser"];
  if (isLoggedIn && user) {
    const { status, role } = user;
    if (
      role === "agencyAdmin" &&
      toRoute.path.includes("/agencyAdmin/agency")
    ) {
      return next();
    }
    if (
      !isAdmin &&
      (status === "pendingTutorial" || status === "completedTutorial")
    ) {
      return next({ path: "/onboarding" });
    }
  }
  // if the user was not logged in, continue to login page
  return next();
};
