
import { ImageName, getSystemFilesURL } from "@/helpers";
import Vue from "vue";

export default Vue.extend({
  name: "QuickLinks",
  props: {
    cellsToShow: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      claimsImgUrl: "",
      billingChangeImgUrl: "",
      propertyChangeImgUrl: "",
      payPlanImgUrl: "",
      quoteHomeImgUrl: "",
      billPayImgUrl: ""
    };
  },
  async mounted() {
    try {
      const fileTypes: { [key: string]: ImageName } = {
        claimsImgUrl: "claims",
        billingChangeImgUrl: "billing-change",
        propertyChangeImgUrl: "property-change",
        payPlanImgUrl: "pay-plan",
        quoteHomeImgUrl: "quote-home",
        billPayImgUrl: "bill-pay"
      };
      const vm = this as Record<string, any>;
      for (const key in fileTypes) {
        const url = await getSystemFilesURL(fileTypes[key]);
        if (url) {
          vm[key] = url;
        }
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  }
});
