import Vue from "vue";
import {
  IBillingChangeRequest,
  IPayPlanChangeRequest,
  IPropertyChangeRequest
} from "@/helpers/storageFormBaseInterface";
import { cloneDeep, get, uniqBy } from "lodash";
import { global } from "@/strings";
import { atlasfileMapActions } from "@/store/modules/atlasfile";
import { printSingleChangeRequest } from "@/helpers/downloadLossForm";

type EditingFields<T> = (payload: { key: keyof T; value: any }) => void;
type ChangeRequestType =
  | IPayPlanChangeRequest
  | IPropertyChangeRequest
  | IBillingChangeRequest;
interface IData<T> {
  editingField: EditingFields<T> | null;
  loading: boolean;
  insuredInfo: Record<string, any>;
  modalMessage: string;
  modalButtonYesClick: boolean;
  insuredKeys: string[];
  disablePolicyInfoComponent: boolean;
  attachmentIds: string[];
  uploadedFile: Record<string, any>[];
  submittedBy: string;
  selectedTabKey: string;
  maxBillingChangeRequestRemarksCharacters: number;
}
interface IChangeRequestMutationData {
  fieldKey: string;
  value: any;
}

export default Vue.extend({
  data(): IData<ChangeRequestType> {
    return {
      editingField: null,
      insuredInfo: {},
      loading: false,
      modalButtonYesClick: false,
      modalMessage: "",
      insuredKeys: ["firstName", "lastName"],
      disablePolicyInfoComponent: false,
      attachmentIds: [],
      uploadedFile: [],
      submittedBy: "",
      selectedTabKey: "edit",
      maxBillingChangeRequestRemarksCharacters: 395
    };
  },

  methods: {
    actionIsDeleteMortgage(editingData: ChangeRequestType | null) {
      return editingData?.data?.actions?.mortgageAction === "delete";
    },
    ...atlasfileMapActions(["createAtlasfile"]),
    setPaddedPolicyNumber(paddedPolicyNumber: string): void {
      this.handleChangeRequestCreation({
        fieldKey: `data.policyInfo.policyNumber`,
        value: paddedPolicyNumber
      });
    },
    remarksCharactersLeft(maxCharacters: number, characters: string): number {
      return maxCharacters - characters.length;
    },
    policyIsLapsedAndHasNoAttachments(
      editingData: ChangeRequestType | null
    ): boolean {
      return Boolean(
        editingData?.data?.isLapsed &&
          !this.uploadedFile.length &&
          !get(editingData, "attachments", []).length
      );
    },
    async printCallback(
      editingData: ChangeRequestType | null,
      printAction: string
    ) {
      const policyPrefix =
        editingData &&
        editingData.data &&
        editingData.data.policyInfo &&
        editingData.data.policyInfo.policyPrefix
          ? editingData.data.policyInfo.policyPrefix
          : undefined;
      await printSingleChangeRequest.bind(this)({
        changeRequestIds: editingData?._id,
        policyPrefix,
        printAction
      });
    },
    handleDeleteAttachment(file: any, changeRequest: Record<string, any>) {
      if (changeRequest.attachments && changeRequest.attachments.length) {
        const filteredAttachments = changeRequest.attachments.filter(
          (attachment: any) => attachment._id != file._id
        );
        changeRequest.attachments = filteredAttachments;
      }
    },
    attachmentDeleteHandler(files: Record<string, string>[]) {
      if (this.editingField) {
        this.editingField({ key: "attachments", value: files });
        this.uploadedFile = files;
      }
    },
    hasSelectedMortgageAction(editingData: ChangeRequestType | null) {
      const { endorsementAction, limitAction, mortgageAction } =
        editingData?.data?.actions || {};
      return Boolean(endorsementAction || limitAction || mortgageAction);
    },
    hasValidMortgageBillInsured(editing: IPropertyChangeRequest | null) {
      if (!editing) return false;
      const { mortgage } = editing?.data || {};
      if (editing?.data?.actions?.mortgageAction) {
        if (mortgage?.payor === "billInsured") {
          return Boolean(mortgage?.payPlanType);
        }
        return Boolean(mortgage?.payor);
      }
      return true;
    },
    async setInsuredInfo(insuredInfo: any) {
      this.insuredInfo = insuredInfo;

      if (
        insuredInfo.isLapsed &&
        insuredInfo?.changeRequestType === "cancellationChangeRequest"
      ) {
        this.insuredInfo.changeRequestMade = false;
        this.modalMessage = global.policyLapsedMessage(
          insuredInfo.PolicyNumber,
          insuredInfo.ActualCancellationDt
        );
        this.$modal.show("lapsedPolicyModal");
      } else {
        this.setChangeRequestInsuredData(insuredInfo, this.insuredKeys);
        this.disablePolicyInfoComponent = true;
      }
    },
    handleModalButtonNoClick(resetPolicyInfoFields = true) {
      if (resetPolicyInfoFields) {
        const fields = [
          "policyNumber",
          "policyPrefix",
          "companyNum",
          "effectiveDate"
        ];
        this.resetPolicyInfoFields(fields);
      }
      this.setChangeRequestInsuredData(
        { firstName: "", lastName: "" },
        this.insuredKeys
      );
      this.modalMessage = "";
      this.modalButtonYesClick = false;
      this.$modal.hide("lapsedPolicyModal");
      this.insuredInfo.changeRequestMade = false;
    },
    async handleModalButtonYesClick() {
      if (this.modalButtonYesClick) {
        this.handleModalButtonNoClick(false);
        this.setChangeRequestInsuredData(this.insuredInfo, this.insuredKeys);
        if (this.editingField) {
          this.editingField({ key: "data.isLapsed" as any, value: true });
        }
        this.insuredInfo.changeRequestMade = true;
      } else {
        this.modalMessage = global.policyLapsedEffectiveDateMessage;
        this.modalButtonYesClick = true;
      }
    },
    goBackToListView(url: string) {
      this.$router.push(url).catch(() => {});
    },
    resetPolicyInfoFields(fields: string[]) {
      for (const field of fields) {
        this.handleChangeRequestCreation(
          {
            fieldKey: field,
            value: ""
          },
          "data.policyInfo"
        );
      }
    },
    handleChangeRequestCreation(
      createdEditing: IChangeRequestMutationData,
      keyPrefix: string = ""
    ) {
      const { fieldKey, value } = createdEditing;
      keyPrefix = keyPrefix ? `${keyPrefix}.` : keyPrefix;
      const mutationKey = `${keyPrefix}${fieldKey}`;
      const key = mutationKey;
      if (this.editingField) {
        this.editingField({
          key: key as any,
          value: value
        });
      }
    },
    setChangeRequestInsuredData(
      insuredInfo: Record<string, any>,
      fields: string[]
    ) {
      for (let i = 0; i < fields.length; i++) {
        this.handleChangeRequestCreation({
          fieldKey: `data.insured.${fields[i]}`,
          value: get(insuredInfo, `${fields[i]}`, "")
        });
      }
    },
    createFormData(files: File[]) {
      const formData = new FormData();
      files.forEach((file: File) => formData.append(file.name, file));
      formData.append("type", "attachment");
      formData.append("isPublic", "false");
      return formData;
    },
    filterAttachmentData(attachments: any) {
      return attachments
        ?.filter((file: any) => file._id)
        .map((file: any) => file._id);
    },

    mapFileData(response: any, editing: ChangeRequestType | null) {
      if (this.editingField) {
        if (response.length) {
          const cloneEditing = cloneDeep(editing?.attachments) || [];
          this.editingField({
            key: "attachments",
            value: this.filterMappedFiles(cloneEditing, response)
          });
        } else {
          const newEditing: any = cloneDeep(editing?.attachments);
          const fileIndex = newEditing.findIndex(
            (file: File) => file.name === response.name
          );
          if (response._id && fileIndex !== -1) {
            newEditing[fileIndex] = response;
          } else {
            newEditing.push(response);
          }
          this.editingField({ key: "attachments", value: newEditing });
        }
      }
    },
    filterMappedFiles(existingArray: any[], newArray: Record<string, any>[]) {
      const uniqueExistingArray = uniqBy(existingArray, "name");
      newArray.forEach((newObject: Record<string, any>) => {
        const index = uniqueExistingArray.findIndex(
          (existingObject: Record<string, any>) =>
            existingObject["name"] === newObject["name"]
        );
        if (index !== -1) {
          uniqueExistingArray[index] = newObject;
        } else {
          uniqueExistingArray.push(newObject);
        }
      });
      return uniqueExistingArray;
    },
    temporalMapFileData(attachments: any, editing: ChangeRequestType | null) {
      if (this.editingField) {
        if (attachments.length) {
          const cloneEditing = cloneDeep(editing?.attachments) || [];
          const newEditing: any = [...cloneEditing, ...attachments];
          this.editingField({ key: "attachments", value: newEditing });
        } else {
          const newEditing: any = cloneDeep(editing?.attachments);
          newEditing.push(attachments);
          this.editingField({ key: "attachments", value: newEditing });
        }
      }
    }
  }
});
