
import { FormBlock } from "@/components/FormBuilder/types";
import insuredSchemas from "@/forms/shared/changeRequestInsuredSchema";
import PolicyInfo from "@/components/User/PolicyInfo.vue";
import { getPayPlanChangeRequestDefaults } from "@/helpers/getPayPlanChangeRequestDefaults";
import ChangeRequestFileUpload from "@/components/AttachmentUploader/ChangeRequestFileUpload.vue";
import {
  payPlanChangeRequestMapActions,
  payPlanChangeRequestMapGetters,
  payPlanChangeRequestMapMutations,
  payPlanChangeRequestMapState
} from "@/store/modules/payplanchangerequest";
import { propertyChangeRequestMapState } from "@/store/modules/propertychangerequest";
import * as types from "@/store/mutation-types";
import { checkObjectForFalsyValues, validateFields } from "@/helpers";
import { cloneDeep, get, set } from "lodash";
import VueWithMixins from "@/helpers/mixins";
import ChangeRequestMixin from "../../ChangeRequests/ChangeRequestMixin";
import { payPlanOptions } from "@/helpers/selectOptions";

interface IData {
  loading: boolean;
  error: string;
  message: string;
  loadingText: string;
  isAdmin: boolean;
  hasValidCredentials: boolean;
  myAccordionCategory: any[];
  activeNames: any[];
  touchedContent: {
    visible: boolean;
    name: string;
    ref: string;
  };
  insuredSchemas: FormBlock[];
  consistencyRadioButton: string;
  EndorsementsLimitsTextAreaLimitNumber: string;
  BillMortgageRadioButton: string;
  paymentPlanRadioButton: string;
  MortgageRadioButton: string;
  MortgageTextAreaLimitNumber: string;
  billToRadioButton: string;
  CoverageLimitsTextAreaLimitNumber: string;
  EndorsementsLimitsRadioButton: string;
  agentCertifyCheckBox: boolean;
  payPlanChangeRequestId: string;
  showForeclosureAlert: boolean;
  disablePolicyInfoComponent: boolean;
  insuredInfo: Record<string, any>;
  modalMessage: string;
  modalButtonYesClick: boolean;
  insuredKeys: string[];
  payPlanBillToKey: string;
  payPlanMortgageValue: string;
}
export default VueWithMixins(ChangeRequestMixin).extend({
  name: "AddPayPlanChangeRequest",
  components: {
    PolicyInfo,
    ChangeRequestFileUpload,
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue"),
    ChangeRequestInsured: () =>
      import("@/components/User/ChangeRequestInsured.vue")
  },
  data(): IData {
    return {
      loading: false,
      showForeclosureAlert: true,
      error: "",
      message: "",
      BillMortgageRadioButton: "",
      billToRadioButton: "",
      MortgageRadioButton: "",
      CoverageLimitsTextAreaLimitNumber: "",
      EndorsementsLimitsRadioButton: "",
      isAdmin: false,
      hasValidCredentials: false,
      MortgageTextAreaLimitNumber: "",
      consistencyRadioButton: "",
      paymentPlanRadioButton: "",
      agentCertifyCheckBox: false,
      touchedContent: {
        visible: false,
        name: "",
        ref: ""
      },
      insuredSchemas,
      payPlanChangeRequestId: "",
      loadingText: "",
      disablePolicyInfoComponent: false,
      EndorsementsLimitsTextAreaLimitNumber: "",
      activeNames: ["1", "2"],
      myAccordionCategory: [
        {
          visible: false,
          name: "Coverage/Limits",
          ref: "coverage"
        },
        {
          visible: false,
          name: "Document Upload",
          ref: "document"
        }
      ],
      insuredInfo: {},
      modalMessage: "",
      modalButtonYesClick: false,
      insuredKeys: ["firstName", "lastName"],
      payPlanBillToKey: "data.payPlan.billTo",
      payPlanMortgageValue: "mortgageeBilled"
    };
  },
  destroyed() {
    this.setState({ key: "editing", value: null });
  },
  created() {
    this.setEdit(getPayPlanChangeRequestDefaults({}));
    this.editingField = this.editField;
  },
  methods: {
    ...payPlanChangeRequestMapActions([
      "createPayPlanChangeRequest",
      "updatePayPlanChangeRequest"
    ]),
    ...payPlanChangeRequestMapMutations({
      editField: types.SET_EDIT_FIELD,
      setEdit: types.SET_EDIT,
      setState: types.SET_STATE
    }),
    handlePayPlanChanges(fieldKey: string, value: string) {
      if (
        fieldKey === this.payPlanBillToKey &&
        value === this.payPlanMortgageValue
      ) {
        this.handleChangeRequestCreation({
          fieldKey: "data.payPlan.payPlanOptions",
          value: ""
        });
        this.paymentPlanRadioButton = "";
      }
      this.handleChangeRequestCreation({
        fieldKey,
        value
      });
    },
    setPaddedPolicyNumber(paddedPolicyNumber: string): void {
      this.handleChangeRequestCreation({
        fieldKey: `data.policyInfo.policyNumber`,
        value: paddedPolicyNumber
      });
    },
    async doUpdate() {
      try {
        let updateData: any = cloneDeep(this.editing);
        delete updateData.data?.agentInfo;
        const updatedFields = {
          ...updateData,
          attachments: this.attachmentIds,
          submittedBy: this.submittedBy
        };
        await this.updatePayPlanChangeRequest({
          id: this.payPlanChangeRequestId,
          update: updatedFields
        });
        this.goBackToListView("/payPlanchangerequests");
      } catch (error) {
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    setMailingAndLocAddresses(addressesArray: any[]) {
      if (addressesArray.length) {
        const insuredAddressKey = [
          "houseNumber",
          "streetName",
          "zipCode",
          "city",
          "state"
        ];
        for (let m = 0; m < addressesArray.length; m++) {
          for (let i = 0; i < insuredAddressKey.length; i++) {
            const key = m == 0 ? "insuredAddress" : "location";

            this.handleChangeRequestCreation({
              fieldKey: `data.insured.${key}.${insuredAddressKey[i]}`,
              value: get(addressesArray[m], `${insuredAddressKey[i]}`, "")
            });
          }
        }
      }
    },
    editPolicyInsuredInfo() {
      this.disablePolicyInfoComponent = false;
      this.insuredInfo.changeRequestMade = false;
      this.insuredInfo.isExempted = false;
      const insuredKey = [
        "lastName",
        "firstName",
        "insuredAddress.houseNumber",
        "insuredAddress.streetName",
        "insuredAddress.zipCode",
        "insuredAddress.city",
        "insuredAddress.state"
      ];
      for (let i = 0; i < insuredKey.length; i++) {
        this.handleChangeRequestCreation({
          fieldKey: `data.insured.${insuredKey[i]}`,
          value: ""
        });
      }
    },
    onToolbarItemSelected(action: string): void {
      switch (action) {
        case "saveChangeRequest":
          this.save();
          break;
        case "submitChangeRequest":
          this.Submit();
          break;
        case "cancel":
          this.goBackToListView("/payPlanchangerequests");
          break;
      }
    },
    updateIsValid() {
      this.hasValidCredentials = this.$refs
        ? validateFields(this.$refs)
        : false;
    },
    attachmentUploadHandler(data: any) {
      this.uploadedFile = [...data, ...this.uploadedFile];
      this.temporalMapFileData(data, this.editing);
    },
    async sendFormData(type: string) {
      try {
        if (this.payPlanChangeRequestId) {
          return await this.doUpdate();
        }
        let updatedFields = { ...this.editing };
        if (this.attachmentIds && this.attachmentIds.length) {
          set(updatedFields, "attachments", this.attachmentIds);
        }
        const response = await this.createPayPlanChangeRequest(updatedFields);
        this.uploadedFile = [];
        const { id, submittedBy } = response;
        this.submittedBy = submittedBy;
        this.payPlanChangeRequestId = id;
        type = type === "Submit" ? "submitted" : "saved";
        this.$appNotifySuccess(`Pay Plan Change Request ${type}`);
      } catch (error) {
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    attachmentData(data: any) {
      this.uploadedFile = data;
    },
    Submit() {
      this.setPayPlanRequestAdditionalData("Submit");
      this.doCreate("Submit");
    },
    save() {
      this.setPayPlanRequestAdditionalData("save");
      this.doCreate();
    },
    async doCreate(type = "save"): Promise<void> {
      if (Object.keys(this.editing as any).length === 0) {
        return;
      }
      try {
        this.loading = true;
        if (!this.uploadedFile.length) {
          return await this.sendFormData(type);
        }
        let response = await this.createAtlasfile(
          this.createFormData(this.uploadedFile as any)
        );
        this.mapFileData(response, this.editing);

        if (response.length) {
          const attachmentIds = response.map((file: any) => file._id);
          this.attachmentIds = [...this.attachmentIds, ...attachmentIds];
        } else {
          this.attachmentIds = [...this.attachmentIds, response._id];
        }
        await this.sendFormData(type);
      } catch (error) {
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.$emit("scrollTop");
      }
    },
    setPayPlanRequestAdditionalData(option = "save") {
      const submittedOn = new Date();
      let status = "Not Submitted";

      if (option == "Submit") {
        this.editField({ key: "submittedOn", value: submittedOn });
        status = "Submitted";
      }
      this.editField({ key: "status", value: status });
    }
  },
  computed: {
    ...payPlanChangeRequestMapState(["editing", "makingApiRequest"]),
    ...payPlanChangeRequestMapGetters(["getPayPlanChangeRequestsFromStore"]),
    ...propertyChangeRequestMapState({
      loadingInsuredInfo: "makingApiRequest"
    }),
    payPlanBillingOptions() {
      return payPlanOptions();
    },
    secondaryToolbar(): any {
      if (this.editing) {
        return {
          text: "Actions",
          key: "actions",
          subItems: [{ title: "Cancel", command: "cancel" }]
        };
      }
      return undefined;
    },
    disablePrimaryButton(): boolean {
      return Boolean(
        this.checkForEmptyRequiredFields ||
          this.policyIsLapsedAndHasNoAttachments(this.editing)
      );
    },
    primaryToolbar(): Record<string, any> | null {
      if (
        this.insuredInfo &&
        this.insuredInfo.changeRequestMade &&
        !this.payPlanChangeRequestId
      ) {
        return {
          text: "Save",
          key: "saveChangeRequest",
          disabled: this.disablePrimaryButton,
          loading: this.loading
        };
      } else if (this.payPlanChangeRequestId) {
        return {
          text: "Submit",
          key: "submitChangeRequest",
          disabled: this.disablePrimaryButton,
          loading: this.loading
        };
      }

      return null;
    },
    checkForEmptyRequiredFields() {
      if (this.editing && this.editing.data && this.editing.data.payPlan) {
        const payPlan = get(this.editing.data, "payPlan", {});
        if (
          checkObjectForFalsyValues(payPlan, "billTo") ||
          checkObjectForFalsyValues(payPlan, "payPlanOptions")
        ) {
          return true;
        }
        return false;
      }
      return true;
    }
  }
});
