var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"topActionButtonPrimary":{
    text: 'Print',
    key: 'print',
    loading: _vm.makingApiRequest
  }},on:{"toolbarSelectItem":_vm.onToolbarItemSelected}},[(_vm.pageDataInitialized && !_vm.resourceNotFound)?_c('div',[(
        _vm.$getCurrentUser &&
          _vm.$getCurrentUser.role == 'admin' &&
          !_vm.lossFormData.isLegacy
      )?_c('TabMenu',{staticClass:"mb-12",attrs:{"menuItems":_vm.viewPageMenuItems,"selectedTabKey":_vm.selectedTabKeyView},on:{"tabClicked":_vm.onTabItemClick}}):_vm._e(),(!_vm.showActivities)?_c('div',[_c('LossFormClaimInfo',{attrs:{"lossFormData":_vm.lossFormData}}),_c('DisplayData',{attrs:{"data":_vm.getData}})],1):(_vm.showActivities)?_c('ActivitiesView',{attrs:{"queryOverride":_vm.queryOverride},scopedSlots:_vm._u([{key:"default",fn:function({ activity }){return [_c('ActivitiesTimelineItem',{attrs:{"activity":activity}})]}}],null,false,3991501741)}):_vm._e()],1):(_vm.resourceNotFound)?_c('ResourceNotFound',{attrs:{"title":"Sorry! Loss form not found.","actionButtonTitle":"Back to claims","actionRedirectPath":"/claims"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }