import Vue from "vue";
import {
  quoteCountyValidationMapMutations,
  quoteCountyValidationMapState
} from "@/store/modules/quoteCountyValidation";
import { SET_EDIT_FIELD } from "@/store/mutation-types";
import { quoteCountyValidationsForm } from "@/forms/admin/company";
import { getCompaniesForFilter } from "@/helpers/companies";
import { FormBlock } from "@/components/FormBuilder/types";
import { IQuoteCountyValidation } from "@/store/modules/quoteCountyValidation/types";
import { pick } from "lodash";
import { getQuoteCountyValidationsDefaults } from "@/helpers/quoteCountyValidation";

export default Vue.extend({
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      disablePrimaryButton: true,
      loadingText: "",
      resourceNotFound: false,
      quoteCountyValidation: {}
    };
  },

  methods: {
    formFieldChangedHandler({
      key,
      value
    }: {
      key: keyof IQuoteCountyValidation;
      value: any;
    }) {
      this.editField({ key, value });
    },
    ...quoteCountyValidationMapMutations(["SET_EDIT"]),
    ...quoteCountyValidationMapMutations({ editField: SET_EDIT_FIELD })
  },
  computed: {
    ...quoteCountyValidationMapState(["editing", "makingApiRequest"]),
    hasAtLeastOneRequiredField(): boolean {
      const requiredFields = pick(this.editing, ["county", "zipCode", "city"]);

      return Object.values(requiredFields).some(value => {
        return Array.isArray(value) ? value.length : !!value;
      });
    },
    quoteCountyValidationsSchema(): FormBlock[] {
      return quoteCountyValidationsForm(!this.editing?.isActive);
    },
    companyOptions() {
      const companies = getCompaniesForFilter();
      return companies;
    },
    formValueDefaults() {
      return getQuoteCountyValidationsDefaults({});
    }
  }
});
