
import { logoPath } from "@/helpers/generalHelpers";
import { version } from "../../../package.json";
import Vue from "vue";

interface ILoadingData {
  version: string;
  loadingImage: string;
}

export default Vue.extend({
  name: "AppLoading",
  data(): ILoadingData {
    return { version, loadingImage: "" };
  },
  async mounted() {
    try {
      const url = await logoPath();
      if (url) {
        this.loadingImage = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  },
  methods: {
    getLogoPath() {
      return logoPath();
    }
  }
});
