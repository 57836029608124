import { render, staticRenderFns } from "./UserPhysicalAddressFields.vue?vue&type=template&id=073f6692&scoped=true"
import script from "./UserPhysicalAddressFields.vue?vue&type=script&lang=ts"
export * from "./UserPhysicalAddressFields.vue?vue&type=script&lang=ts"
import style0 from "./UserPhysicalAddressFields.vue?vue&type=style&index=0&id=073f6692&prod&scoped=true&lang=css"
import style1 from "./UserPhysicalAddressFields.vue?vue&type=style&index=1&id=073f6692&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073f6692",
  null
  
)

export default component.exports