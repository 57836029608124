
import Vue from "vue";

import {
  userMapActions,
  userMapGetters,
  userMapState
} from "@/store/modules/user";
import { authMapGetters } from "@/store/modules/auth";
import ListUsersTable from "@/components/User/ListUsers/ListUsersTable.vue";

import Charts from "@/components/Charts/Charts.vue";
interface IListUsersData {
  loading: boolean;
  showBannedOrRestrictedUsersUploader: boolean;
  banUser: boolean;
  errorMessage: string;
  successMessage: string;
  showUsersChart: string;
}

export default Vue.extend({
  name: "list-users",
  props: {
    isPage: {
      type: Boolean,
      default: true,
      required: false
    },
    hideCreateButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSearchBar: {
      type: Boolean,
      required: false,
      default: false
    },
    queryOverride: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  components: { ListUsersTable, Charts },
  data(): IListUsersData {
    return {
      loading: false,
      showBannedOrRestrictedUsersUploader: false,
      banUser: true,
      errorMessage: "",
      successMessage: "",
      showUsersChart: ""
    };
  },
  methods: {
    ...userMapActions(["getUsers"]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.createCallback();
          break;
        case "active-users":
        case "user-distribution":
          this.showUsersChart = event;
          break;
        case "users":
          this.showUsersChart = "";
          break;
      }
    },
    uploadSuccess() {
      const query = this.banUser
        ? { isBanned: true }
        : { "isRestricted.startDate__gt": new Date() };
      this.fetchPageData(query);
    },
    async fetchPageData(queryObject: any): Promise<void> {
      // const gtagTimer = this.__gtagMeasureTiming(
      //   "fetchPageData",
      //   "users",
      //   "admin"
      // );
      try {
        this.loading = true;
        this.$emit("scrollTop");
        await this.getUsers({
          query: { ...queryObject, ...this.queryOverride }
        });
      } catch (error) {
        console.error(error);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        // gtagTimer.done();
      }
    },

    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      if (
        this.getCurrentUser &&
        (this.getCurrentUser.role == "agencyAdmin" ||
          this.getCurrentUser.role == "subAdmin")
      ) {
        return this.$router.push(`/agencyAdmin/users/${_id}`).catch(() => {});
      }
      this.$router.push(`/admin/users/${_id}`).catch(() => {});
    },
    async createCallback(): Promise<any> {
      if (
        this.getCurrentUser &&
        (this.getCurrentUser.role == "agencyAdmin" ||
          this.getCurrentUser.role == "subAdmin")
      ) {
        return this.$router.push("/agencyAdmin/users/new").catch(() => {});
      }
      this.$router.push("/admin/users/new").catch(() => {});
    },
    uploadBannedOrRestrictedUsers(purpose: string) {
      this.banUser = purpose === "banned";
      this.showBannedOrRestrictedUsersUploader = true;
    }
  },
  computed: {
    ...userMapGetters(["getUsersFromStore", "getUserLinks", "getTotalRecords"]),
    ...authMapGetters(["getCurrentUser"]),
    ...userMapState(["makingApiRequest"]),
    banOrRestrictionLegend(): string {
      const restrictedLegend =
        "*CSV file must have the following columns: EMAIL, START_DATE, END_DATE";
      const bannedLegend = "*CSV file must have EMAIL header";
      return this.banUser ? bannedLegend : restrictedLegend;
    },
    banOrRestrictionUrl(): string {
      return this.banUser ? "users/banned/upload" : "users/restrict/upload";
    },
    uploadTemplateCollection(): string {
      return this.banUser ? "userBan" : "userRestriction";
    },
    chart(): string {
      const { charts = [] } = this.$store.state.auth["initData"] || {};
      return charts.find((chart: any) => chart.id === this.showUsersChart);
    },
    topButtonSecondaryItems(): {
      text?: string;
      key?: string;
      subItems?: { title: string; command: string }[];
    } | null {
      const ops = [
        {
          title: "Active Users Report",
          command: "active-users"
        },
        {
          title: "User Report",
          command: "user-distribution"
        }
      ];
      if (this.showUsersChart) {
        ops.push({
          title: "Users",
          command: "users"
        });
      }
      if (this.$getCurrentUser?.role === "admin") {
        return {
          text: "Actions",
          key: "actions",
          subItems: ops
        };
      }
      return null;
    }
  }
});
