import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  CommunicationState,
  CommunicationMutations,
  StateKeys,
  ICommunication,
  ISendGridCommunicationData
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { getCommunicationDefaults } from "@/helpers/defaultObjectGenerators";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  CommunicationMutations,
  CommunicationState
> = {
  [types.SET_STATE]: GenericSetState<CommunicationState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<CommunicationState>(),
  [types.SET_EDIT]: GenericSetEdit<CommunicationState, string | ICommunication>(
    "communications",
    getCommunicationDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    CommunicationState,
    keyof ICommunication
  >(),
  [types.SET_ITEM]: GenericSetItem<CommunicationState, ICommunication>(
    "communications",
    getCommunicationDefaults
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<CommunicationState>(
    "communications",
    "_id"
  ),
  [types.CLEAR_COMMUNICATION_STORE]: GenericClearStore<CommunicationState>(
    defaultState()
  ),
  [types.ADD_COMMUNICATION_EVENT](
    state: CommunicationState,
    data: ISendGridCommunicationData
  ) {
    const recipients = state?.recipients;

    const dataToUpdate = recipients?.find(
      (recipient: any) => recipient?.userId === data?.userId
    );
    dataToUpdate?.events?.unshift({
      data,
      userId: data.userId
    });
  }
};
