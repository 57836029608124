
import Vue from "vue";
import { ITableWrapperColumns, IUserRoleStrings } from "@/types";
import { tableColumns, agentsTableColumns } from "./TableColumns";
import {
  atlasfileMapActions,
  atlasfileMapGetters,
  atlasfileMapMutations,
  atlasfileMapState
} from "@/store/modules/atlasfile";
import UploadArea from "@/components/UploadArea/UploadArea.vue";
import { getPathAPI, sortArrayOfObjects } from "@/helpers";
// import { AtlasFilesCategoryOptions } from "@/helpers/selectOptions";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { authMapGetters } from "@/store/modules/auth";
import { atlasFileCategoryMapActions } from "@/store/modules/atlasFileCategory";
import { atlasConsole } from "@/helpers/logger";
import axios from "axios";
interface IListUsersData {
  loading: boolean;
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  agentsTableColumns: ITableWrapperColumns;
  showUploadModal: boolean;
  categoryFilterOptions: any[];
  errorMessage: string;
  documentTitle: string;
  documentId: string;
  loadingText: string;
  categoryOptions: { label: string; value: string }[];
}

export default Vue.extend({
  name: "listAtlasFiles",
  components: {
    UploadArea,
    AtlasFileEdit: () => import("./AtlasFileEdit.vue"),
    AtlasFileSelectCategory: () => import("./AtlasFileSelectCategory.vue"),
    NoResultsFound
  },
  data(): IListUsersData {
    return {
      loading: false,
      tableActions: [],
      tableColumns: tableColumns,
      agentsTableColumns: agentsTableColumns,
      showUploadModal: false,
      errorMessage: "",
      documentTitle: "",
      documentId: "",
      categoryFilterOptions: [],
      loadingText: "",
      categoryOptions: []
    };
  },
  mounted() {
    this.fetchCategories().then(() => {
      this.$modal.show("selectCategoryModal");
    });
  },
  methods: {
    ...atlasfileMapActions(["getAtlasfiles", "deleteAtlasFile"]),
    ...atlasfileMapMutations(["SET_ITEM", "SET_EDIT"]),
    ...atlasFileCategoryMapActions(["getAtlasFileCategories"]),
    handleCloseAtlasFileEditModal() {
      this.$modal.hide("edit-file-modal");
    },
    downloadFile(item: any) {
      if (this.loading) return;
      const accessToken = localStorage.getItem("access");
      const baseUrl = getPathAPI();
      this.loading = true;
      const url = `${baseUrl}/file/view/${item._id}?accessToken=${accessToken}`;
      axios({
        url: url,
        method: "GET"
      })
        .then(() => {
          const element = document.createElement("a");
          element.href = url;
          element.download = "";
          element.target = "_blank";
          element.click();
        })
        .catch((error: any) => {
          this.$appNotifyError(error.response.data.response.message);
          this.$bugSnagClient.notify(error);
        })
        .finally(() => (this.loading = false));
    },
    async fetchPageData(queryObject: any): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Loading files. Please wait...";
        this.$emit("scrollTop");
        await this.getAtlasfiles({ query: queryObject });
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async createCallback(): Promise<any> {
      this.$modal.show("upload-document-modal");
      this.showUploadModal = true;
    },
    onFileUploadSuccess(doc: { response: any; success: boolean }): void {
      this.$appNotifySuccess("File Upload Success");
      this.showUploadModal = false;
      this.$modal.hide("upload-document-modal");
      this.SET_ITEM(doc.response);
    },
    onFileUploadError(err: any) {
      this.errorMessage = err.message;
    },
    async confirmRemove(): Promise<void> {
      this.$modal.show("atlasFilesDeleteModal");
    },
    async handleResponseAction(payload: {
      name: string;
      data: Record<string, any>;
    }) {
      switch (payload.name) {
        case "edit":
          this.SET_EDIT(payload.data as any);
          this.$modal.show("edit-file-modal");
          break;
        case "delete":
          try {
            this.loading = true;
            const { _id, name } = payload.data;
            this.documentId = _id;
            this.documentTitle = name;
            await this.confirmRemove();
          } catch (error) {
            //do nothing
            this.$bugSnagClient.notify(error);
          } finally {
            this.loading = false;
          }
          break;
        case "view":
          this.downloadFile(payload.data);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneAtlasFile() {
      await this.deleteAtlasFile(this.documentId);
      this.$modal.hide("atlasFilesDeleteModal");
      this.$appNotifySuccess("Document deleted successfully");
    },
    async onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.$modal.show("upload-document-modal");
          break;
        case "add-category":
          this.$router.push("/admin/documents/category/new");
          break;
        case "atlas-files-activities":
          this.$router.push("/admin/documents/activities");
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async fetchCategories(): Promise<void> {
      try {
        const categories = await this.getAtlasFileCategories({});
        this.categoryFilterOptions = categories.map((category: any) => {
          return { label: category.title, value: category.title };
        });
        if (
          this.$getCurrentUser &&
          this.$getCurrentUser.role &&
          this.$getCurrentUser.role === "admin"
        ) {
          this.categoryFilterOptions.push({
            label: "Uploaded Documents",
            value: "uploaded"
          });
        }
        sortArrayOfObjects(this.categoryFilterOptions, "label");
        atlasConsole.log(
          "Category Filter Options",
          "Info",
          this.categoryFilterOptions as any
        );
      } catch (error) {
        //do nothing
        this.$bugSnagClient.notify(error);
      }
    },
    async setCategory($event: string): Promise<void> {
      await this.fetchPageData({ category: $event, __limit: 50, __page: 1 });
      this.$route.query["category__in"] = $event;
      this.$router.replace({
        path: "/documents",
        query: { category__in: $event, __limit: "50", __page: "1" }
      });
      this.$modal.hide("selectCategoryModal");
    }
  },
  computed: {
    ...atlasfileMapState(["atlasfiles", "links", "makingApiRequest"]),
    ...authMapGetters(["getCurrentUser"]),
    ...atlasfileMapGetters(["getTotalRecords"]),

    userRole(): IUserRoleStrings | null {
      return this.getCurrentUser && this.getCurrentUser.role
        ? this.getCurrentUser.role
        : null;
    },
    userCanUploadDocument(): boolean {
      return Boolean(
        this.userRole && ["dataEntry", "admin"].includes(this.userRole)
      );
    },
    primaryButton() {
      if (this.userCanUploadDocument) {
        return {
          text: "Upload Document",
          key: "create"
        };
      }
      return null;
    },
    secondaryButton() {
      if (this.userCanUploadDocument) {
        const button: {
          text: string;
          key: string;
          subItems: { title: string; command: string }[];
        } = {
          text: "Actions",
          key: "actions",
          subItems: [
            {
              title: "Add Document Category",
              command: "add-category"
            }
          ]
        };

        if (this.userRole === "admin") {
          button.subItems.push({
            title: "All Activities",
            command: "atlas-files-activities"
          });
        }

        return button;
      }
      return null;
    },
    filters() {
      let filters: any[] = [
        {
          field: "category",
          title: "Category",
          options: this.categoryFilterOptions
        }
      ];
      if (
        this.$getCurrentUser &&
        this.$getCurrentUser.role &&
        this.$getCurrentUser.role === "admin"
      ) {
        const moreFilters = [
          {
            field: "isPublic",
            title: "Is Public",
            options: [
              { label: "Yes", value: "true" },
              { label: "No", value: "false" }
            ]
          },
          {
            field: "ext",
            title: "File Type",
            options: [
              { label: "PDF", value: "pdf" },
              { label: "CSV", value: "csv" },
              { label: "PNG", value: "png" },
              { label: "JPEG", value: "jpeg" }
            ]
          },
          {
            field: "createdOn",
            title: "Created On",
            options: [],
            isDate: true
          }
        ];
        filters = [...filters, ...moreFilters];
      }
      return filters;
    },
    tableRowActions() {
      let actions = [{ label: "View", value: "view", faIcon: "book-open" }];
      if (
        this.$getCurrentUser &&
        this.$getCurrentUser.role &&
        this.$getCurrentUser.role === "admin"
      ) {
        const adminActions = [
          { label: "Edit", value: "edit", faIcon: "edit" },
          {
            label: "Delete",
            value: "delete",
            faIcon: "trash-alt",
            class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
          }
        ];
        actions = [...actions, ...adminActions];
      }
      return actions;
    }
  }
});
