var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage},on:{"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading || _vm.loadingText),expression:"loading || loadingText"}],attrs:{"element-loading-text":_vm.loadingText,"data":_vm.agentApplications,"tableColumns":_vm.tableColumns,"paginationLinks":_vm.getAgentApplicationLinks,"filters":_vm.filters,"totalRecords":_vm.getTotalRecords,"tableRowActions":_vm.tableRowActions},on:{"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewCallback,"table-row-action":_vm.handleResponseAction}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Agent Applications Found."},slot:"notFound"})],1),_c('ModalMessage',{attrs:{"name":"agentApplicationsModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Agent Application Deletion","description":"This will delete the Agent Application. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.deleteOneAgentApplication()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('agentApplicationsModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }