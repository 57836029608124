var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonPrimary":{
    text: 'Create Agency',
    key: 'create',
    disabled: !_vm.formIsValid,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancelAddingNewAgency'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[_c('form-builder',{ref:"formElement",attrs:{"schemas":_vm.agencyFields(),"formValues":_vm.editing,"dynamicOptions":{
      companyIds: _vm.companyOptions
    }},on:{"formFieldChanged":_vm.formFieldChangedHandler,"formChangedVisibly":_vm.formFieldBlurHandler,"validationChanged":function($event){_vm.validationData = $event}}}),_c('ModalMessage',{attrs:{"name":"acknowledgeDataSharing","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Share Data?","description":_vm.warningString,"showClose":true,"buttons":[
      {
        text: `Ok`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.acceptEzlynxAcknowledgement()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.declineEzlynxAcknowledgement()
      }
    ]}}),_c('AgencyCodePrefixPickerModal',{attrs:{"companyIds":_vm.editing && _vm.editing.companyIds ? _vm.editing.companyIds : []},on:{"prefixSelected":_vm.prefixSelected}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }