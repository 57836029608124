
import Vue from "vue";
import { getPathAPI } from "@/helpers";
interface IData {
  accessToken: string | null;
}
export default Vue.extend({
  name: "DataView",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data(): IData {
    return {
      accessToken:
        typeof localStorage !== "undefined"
          ? localStorage.getItem("access")
          : null
    };
  },
  methods: {
    url() {
      return getPathAPI();
    }
  },
  computed: {}
});
