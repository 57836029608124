var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"topActionButtonPrimary":{
    text: 'Create loss form',
    key: 'create-loss-form',
    disabled: _vm.formIsNotValid,
    loading: _vm.makingApiRequest
  }},on:{"toolbarSelectItem":_vm.toolbarSelectItem}},[_c('div',[_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between"},[_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Create New Loss Form ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Enter the company and policy information to get started. ")])])]),_c('ModalMessage',{attrs:{"name":"exceededClaims","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Claim Exists","description":_vm.modalMessage,"showClose":true,"buttons":[
        {
          text: `View Existing Claim`,
          classList: ['bg-gray-100 text-gray-600 border-gray-300'],
          click: _vm.goToExistingClaim
        },
        {
          text: `Create New Claim`,
          classList: [''],
          click: _vm.goToLossForm
        }
      ]}}),_c('LossForm',{ref:"lossFormComponent",attrs:{"formType":"fileClaim"},on:{"success":_vm.goToLossForm,"exceeded":_vm.showExceededClaimsModal,"isFormNotValid":_vm.handleFormField}})],1),_c('Help',{attrs:{"title":"Claims Inquiries","messages":_vm.messages}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }