import Vue from "vue";
import {
  emergencyMapActions,
  emergencyMapState
} from "@/store/modules/emergency";
import { quickpayMapState } from "@/store/modules/quickpay";
import { logoPath } from "@/helpers/generalHelpers";
import { getSystemFilesURL } from "@/helpers";
export default Vue.extend({
  data() {
    return {
      modalDetails: { name: "", description: "", title: "", icon: "" },
      activeTabName: "inquiry",
      tempEmergency: false,
      logoPath: "",
      quickPayBackgroundImage: ""
    };
  },
  async mounted() {
    try {
      const [logoPathImage, quickPayBackgroundImage] = await Promise.all([
        logoPath(),
        getSystemFilesURL("quickpay-bg")
      ]);
      if (logoPathImage) {
        this.logoPath = logoPathImage;
      }
      if (quickPayBackgroundImage) {
        this.quickPayBackgroundImage = quickPayBackgroundImage;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  },
  methods: {
    ...emergencyMapActions(["checkEmergency"]),
    getLogoPath() {
      return logoPath();
    },
    handleClick() {
      //do nothing
    },
    showModal(details: {
      title: string;
      description: string;
      icon: string;
      modalName: string;
    }) {
      this.modalDetails.title = details.title;
      this.modalDetails.description = details.description;
      this.modalDetails.icon = details.icon;
      this.$modal.show(details.modalName);
    },
    doShutDown() {
      this.$router.push({
        path: `/emergency-maintenance`,
        query: {
          message:
            "Sorry, payment feature is undergoing emergency maintenance. Please try again later"
        }
      });
    },
    gotoPrevious() {
      this.activeTabName = "inquiry";
    }
  },
  computed: {
    ...quickpayMapState(["makingApiRequest", "policyInfo"]),
    ...emergencyMapState(["emergencyData"]),
    initWithBillPayData(): boolean {
      return !!(
        this.$route.query.prefix &&
        this.$route.query.policyNumber &&
        this.$route.query.companyNumber
      );
    }
  },
  watch: {
    "emergencyData.isInEmergencyMaintenanceMode": {
      handler(isInEmergencyMaintenanceMode: boolean) {
        if (isInEmergencyMaintenanceMode) {
          this.doShutDown();
        }
      },
      immediate: false
    },
    "emergencyData.isInFiservScheduledMaintenanceMode": {
      handler(isInFiservScheduledMaintenanceMode: boolean) {
        if (isInFiservScheduledMaintenanceMode) {
          this.$router.push({
            path: `/emergency-maintenance`,
            query: {
              message: this.emergencyData?.fiservMaintenanceModeMessage
            }
          });
        }
      },
      immediate: false
    }
  }
});
