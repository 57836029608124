var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"hideAllFilters":false,"hideSearchBar":!_vm.isAdmin,"element-loading-text":_vm.loadingText,"data":_vm.adjusterfiles,"tableActions":_vm.tableActions,"tableColumns":_vm.tableColumns,"hideToggleIcons":true,"totalRecords":_vm.getTotalRecords,"paginationLinks":_vm.getAdjusterLinks ? _vm.getAdjusterLinks : {},"filters":_vm.filters},on:{"table-row-action":_vm.handleResponseAction,"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewCallback,"table-action":_vm.tableActionHandler}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Adjuster Reports Found."},slot:"notFound"})],1),_c('ModalMessage',{attrs:{"name":"adjusterPortalModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Adjuster Deletion","description":"This will delete the Adjuster. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.deleteOneAdjuster()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('adjusterPortalModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }