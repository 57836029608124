export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const SET_ACTIVITIES = "SET_ACTIVITIES";
export const SET_ACTIVITY_STATE = "SET_ACTIVITY_STATE";
export const SET_ACTIVITY_LINKS = "SET_ACTIVITY_LINKS";
export const DROP_ACTIVITIES = "DROP_ACTIVITIES";

export const SET_COMMUNICATION_STATE = "SET_COMMUNICATION_STATE";
export const SET_COMMUNICATIONS = "SET_COMMUNICATIONS";
export const REMOVE_COMMUNICATION = "REMOVE_COMMUNICATION";
export const DROP_COMMUNICATIONS = "DROP_COMMUNICATIONS";
export const SET_COMMUNICATION_LINKS = "SET_COMMUNICATION_LINKS";
export const SET_EDIT_COMMUNICATION = "SET_EDIT_COMMUNICATION";
export const SET_COMMUNICATION_EDIT_FIELD = "SET_COMMUNICATION_EDIT_FIELD";
export const SET_COMMUNICATION_METRICS = "SET_COMMUNICATION_METRICS";
export const ADD_COMMUNICATION_EVENT = "ADD_COMMUNICATION_EVENT";

export const SET_DOCUMENT_STATE = "SET_DOCUMENT_STATE";
export const SET_DOCUMENT = "SET_DOCUMENT";
export const SET_DOCUMENT_LINKS = "SET_DOCUMENT_LINKS";
export const DROP_DOCUMENTS = "DROP_DOCUMENTS";
export const REMOVE_DOCUMENT = `REMOVE_DOCUMENT`;
export const SET_EDIT_DOCUMENT = `SET_EDIT_DOCUMENT`;
export const SET_DOCUMENT_EDIT_FIELD = `SET_DOCUMENT_EDIT_FIELD`;

export const SET_USER_STATE = "SET_USER_STATE";
export const SET_USER = "SET_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_EDIT_USER = "SET_EDIT_USER";
export const SET_USER_EDIT_FIELD = `SET_USER_EDIT_FIELD`;
export const SET_UNDER_WRITER = `SET_UNDER_WRITER`;

export const SCROLL_TO_TOP = "SCROLL_TO_TOP";

export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const SET_APP_SETTINGS_STATE = "SET_APP_SETTINGS_STATE";
export const REMOVE_APP_SETTINGS = "REMOVE_APP_SETTINGS";
export const SET_APP_SETTINGS_LINKS = "SET_APP_SETTINGS_LINKS";
export const SET_EDIT_APP_SETTINGS = "SET_EDIT_APP_SETTINGS";
export const DROP_APP_SETTINGS = "DROP_APP_SETTINGS";
export const SET_APP_SETTINGS_EDIT_FIELD = `SET_APP_SETTINGS_EDIT_FIELD`;

export const SET_STATE = "SET_STATE";
export const SET_STATES = "SET_STATES";

export const SET_EDIT_FIELD = "SET_EDIT_FIELD";
export const SET_EDIT = "SET_EDIT";
export const SET_ITEM = "SET_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";

export const SET_EDIT_APPROVE = "SET_EDIT_APPROVE";
export const SET_EDITING_APPROVE = "SET_EDITING_APPROVE";

export const SET_SCREEN_NAME = "SET_SCREEN_NAME";

export const SET_PROPERTY_CHANGE_REQUEST = "SET_PROPERTY_CHANGE_REQUEST";
export const DELETE_PROPERTY_CHANGE_REQUEST = "DELETE_PROPERTY_CHANGE_REQUEST";
export const SET_EDIT_PROPERTY_CHANGE_REQUEST =
  "SET_EDIT_PROPERTY_CHANGE_REQUEST";
export const SET_PROPERTY_CHANGE_REQUEST_EDIT_FIELD =
  "SET_PROPERTY_CHANGE_REQUEST_EDIT_FIELD";

export const SET_BILLING_CHANGE_REQUEST = "SET_BILLING_CHANGE_REQUEST";
export const DELETE_BILLING_CHANGE_REQUEST = "DELETE_BILLING_CHANGE_REQUEST";
export const SET_EDIT_BILLING_CHANGE_REQUEST =
  "SET_EDIT_BILLING_CHANGE_REQUEST";
export const SET_BILLING_CHANGE_REQUEST_EDIT_FIELD =
  "SET_BILLING_CHANGE_REQUEST_EDIT_FIELD";
export const SET_CLAIMS_EDIT_FIELD = "SET_CLAIMS_EDIT_FIELD";
export const SET_CLAIMS_FISERV_DATA = "SET_CLAIMS_FISERV_DATA";

export const UPDATE_ENDORSEMENTS = "UPDATE_ENDORSEMENTS";
export const UPDATE_ENDORSEMENT_FIELD = "UPDATE_ENDORSEMENT_FIELD";
export const SET_ENDORSEMENT_VALUE = "SET_ENDORSEMENT_VALUE";
export const SET_ENDORSEMENT_WATERCRAFT_OPTIONS =
  "SET_ENDORSEMENT_WATERCRAFT_OPTIONS";
export const EDIT_DISCOUNT_FIELD = "EDIT_DISCOUNT_FIELD";
export const SET_INSURED_ADDRESS = "SET_INSURED_ADDRESS";
export const EDIT_UNDERWRITING_RESPONSE_FIELD =
  "EDIT_UNDERWRITING_RESPONSE_FIELD";

export const SET_EDIT_OVERRIDE_FIELD = "SET_EDIT_OVERRIDE_FIELD";
export const RESET_MANDATORY_ENDORSEMENTS = "RESET_MANDATORY_ENDORSEMENTS";
export const SET_EDIT_OVERRIDE_FIELD_OTHERS = "SET_EDIT_OVERRIDE_FIELD_OTHERS";
export const SET_CONTENTS_AMOUNTS_COMPANY_OVERRIDES =
  "SET_CONTENTS_AMOUNTS_COMPANY_OVERRIDES";

export const SET_COMPANY_FORM_TYPES = "SET_COMPANY_FORM_TYPES";

export const SET_ACCREDITED_BUILDER_FROM_TYPES =
  "SET_ACCREDITED_BUILDER_FROM_TYPES";

export const CLEAR_ADJUSTERFILE_STORE = "CLEAR_ADJUSTERFILE_STORE";
export const CLEAR_QUOTE_STORE = "CLEAR_QUOTE_STORE";
export const CLEAR_USER_STORE = `CLEAR_USER_STORE`;
export const CLEAR_AGENCY_STORE = "CLEAR_AGENCY_STORE";
export const CLEAR_AGENTAPPLICATIONS_STORE = "CLEAR_AGENTAPPLICATIONS_STORE";
export const CLEAR_AGENT_REPORT_STORE = "CLEAR_AGENT_REPORT_STORE";
export const CLEAR_APP_STORE = "CLEAR_APP_STORE";
export const CLEAR_ATLASFILE_STORE = "CLEAR_ATLASFILE_STORE";
export const CLEAR_BILLING_CHANGE_REQUEST_STORE =
  "CLEAR_BILLING_CHANGE_REQUEST_STORE";
export const CLEAR_CHART_STORE = "CLEAR_CHART_STORE";
export const CLEAR_CLAIMS_STORE = "CLEAR_CLAIMS_STORE";
export const CLEAR_COMMUNICATION_STORE = "CLEAR_COMMUNICATION_STORE";
export const CLEAR_COMPANIES_STORE = "CLEAR_COMPANIES_STORE";
export const CLEAR_ACCREDITED_BUILDER_STORE = "CLEAR_ACCREDITED_BUILDER_STORE";
export const CLEAR_COUNTY_STORE = "CLEAR_COUNTY_STORE";
export const CLEAR_ENDORSEMENT_STORE = "CLEAR_ENDORSEMENT_STORE";
export const CLEAR_GBSHELP_STORE = "CLEAR_GBSHELP_STORE";
export const CLEAR_IMPORT_STORE = "CLEAR_IMPORT_STORE";
export const CLEAR_MORTGAGE_STORE = "CLEAR_MORTGAGE_STORE";
export const ADD_MORTGAGE_OTHER = "ADD_MORTGAGE_OTHER";
export const CLEAR_NOTIFICATION_STORE = "CLEAR_NOTIFICATION_STORE";
export const CLEAR_PAYPLAN_CHANGE_REQUEST_STORE =
  "CLEAR_PAYPLAN_CHANGE_REQUEST_STORE";
export const CLEAR_PERSONAL_LIABILITY_POLICY_STORE =
  "CLEAR_PERSONAL_LIABILITY_POLICY_STORE";
export const CLEAR_PROPERTY_ANALYTICS_STORE = "CLEAR_PROPERTY_ANALYTICS_STORE";
export const CLEAR_PROPERTY_CHANGE_REQUEST_STORE =
  "CLEAR_PROPERTY_CHANGE_REQUEST_STORE";
export const CLEAR_SETTING_STORE = "CLEAR_SETTING_STORE";
export const CLEAR_TRANSACTIONS_STORE = "CLEAR_TRANSACTIONS_STORE";
export const REMOVE_PROPERTY_FROM_STORE = "REMOVE_PROPERTY_FROM_STORE";
export const ADD_PROPERTY_FROM_STORE = "ADD_PROPERTY_FROM_STORE";
export const ADD_RATING_ID = "ADD_RATING_ID";
export const REMOVE_RATING_IDS = "REMOVE_RATING_IDS";
export const CLEAR_QUOTE_COUNTY_VALIDATIONS_STORE =
  "CLEAR_QUOTE_COUNTY_VALIDATIONS_STORE";
export const CLEAR_FAQ_STORE = "CLEAR_FAQ_STORE";
export const CLEAR_ATLAS_PLATFORM_STORE = "CLEAR_ATLAS_PLATFORM_STORE";
