var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalBase',{attrs:{"name":_vm.modalName,"size":"medium","clickToClose":false,"showClose":true,"loading":_vm.loading,"position":"center","bottomButtonPrimary":{
    key: 'continue',
    disabled: _vm.value.length < 5,
    label: 'Continue'
  },"bottomButtonSecondary":{
    key: 'cancel',
    label: 'Cancel'
  }},on:{"primaryButtonClick":function($event){return _vm.$emit('scrubAddress')}}},[_c('custom-alert',{attrs:{"title":"An Error Occurred","message":_vm.errorMessage,"type":"danger","show":!!_vm.errorMessage,"dismissible":false}}),_c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('div',{staticClass:"mb-12",staticStyle:{"width":"110px"}},[_c('img',{attrs:{"src":_vm.mapImage,"alt":"map-image"}})]),_c('div',{staticClass:"text-lg font-semibold mb-8"},[_vm._v(" Type An Address To Populate The Fields ")]),_c('GoogleAutoComplete',{ref:"addressAutoComplete",staticClass:"form-control w-full",attrs:{"id":"map","placeholder":"Type in address to search..."},on:{"placechanged":function($event){return _vm.$emit('placechanged', $event)},"change":function($event){return _vm.$emit('change', $event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }