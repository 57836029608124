
import Vue from "vue";
import BillingForm from "@/components/Billing/BillingForm.vue";
import {
  billingChangeRequestMapActions,
  billingChangeRequestMapState
} from "@/store/modules/billingchangerequest";
import { get, set } from "lodash";
import { IBillingInfoRequest } from "@/store/modules/billingchangerequest/types";
import { makeBlobUrlFromBase64 } from "@/helpers/downloadLossForm";
import { authMapGetters } from "@/store/modules/auth";
import Help from "@/components/Help/Inquiries.vue";
import * as selectOptions from "@/helpers/selectOptions";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import {
  formatCurrencyCompactLong,
  formatCurrencyFloat
} from "@/plugins/filters";
import DataView from "@/components/DataView/DataView.vue";
import {
  padPolicyNumber,
  convertDashToSpace,
  formatAgencyCode
} from "@/helpers/generalHelpers";
import { getOrigin } from "@/helpers";
import { getSite } from "@/helpers/companies";

interface BillingData {
  Summary: {
    TermEffectiveDt: string;
    BillingStatus: string;
    PolicyStatus: string;
    TermExpirationDt: string;
    PolicyNumber: string;
    LastPaymentCheck: string;
    LastPaymentDt: string;
    CurrentDueDt: string;
    PrintName: string;
    compName: string;
    PrintAddress1: string;
    Agent: string;
    PayPlan: string;
    InstallmentsBilled: string;
    InstallmentsRemaining: string;
    PolicyPrefix: string;
    CancelReason: string;
    TotalPaid: string;
    TermFeesTaxes: string;
    PrintAddress3: { State: string; City: string; Zip: string };
  };
  Invoice: any;
  Trans: any;
}

interface SelectOption {
  title: string;
  text: string;
}

interface Message {
  title: string;
  contacts: SelectOption[];
}

interface Form {
  policyNumber: string;
  companyPrefix: string;
  companyNumber: number;
  category: string;
  site: string;
  Summary?: BillingData["Summary"];
  Invoice?: BillingData["Invoice"];
  Trans?: BillingData["Trans"];
  billingType?: "summary" | "trans" | "invoice";
}

interface IData {
  showAlert: boolean;
  errorMessage: string;
  alertType: "warning" | "error";
  successMessage: string;
  loading: boolean;
  billingData: BillingData;
  messages: Message[];
  policyData: IBillingInfoRequest;
  form: Form;
  loadingText: string;
  inquiryIsClosed: boolean;
  showCancellationReasonError: boolean;
}

export default Vue.extend({
  components: {
    BillingForm,
    Help,
    CustomAlert,
    DataView
  },
  data(): IData {
    return {
      showAlert: false,
      alertType: "warning",
      errorMessage: "",
      successMessage: "",
      loading: false,
      billingData: {} as BillingData,
      messages: [
        {
          title: "Billing",
          contacts: selectOptions.billingContacts
        },
        {
          title: "Underwriting",
          contacts: selectOptions.underwritingContacts
        }
      ],
      policyData: {} as IBillingInfoRequest,
      form: {
        policyNumber: "",
        companyPrefix: "",
        companyNumber: 0,
        category: "claims",
        site: "ATLAS",
        billingType: "summary"
      },
      loadingText: "",
      inquiryIsClosed: true,
      showCancellationReasonError: false
    };
  },
  methods: {
    ...billingChangeRequestMapActions(["getBillingInfo", "printBillingInfo"]),
    closeModal() {
      this.$modal.hide("billing-special-status-response-modal");
    },
    closePolicyRedirectModal() {
      this.$modal.hide("policyRedirectModal");
    },
    goToPolicyInquiry() {
      if (this.billingData?.Invoice?.InvoiceDt) {
        this.$router.push({
          path: "/policy-inquiry",
          query: {
            policyPrefix: this.form.companyPrefix,
            policyNumber: this.form.policyNumber,
            companyNumber: this.form.companyNumber.toString(),
            effectiveDate: this.billingData.Invoice.InvoiceDt
          }
        });
      } else {
        this.$modal.show("policyRedirectModal");
      }
    },
    clearData() {
      const { billingForm } = this.$refs;
      (billingForm as any).resetFields();
      (this.billingData as any) = {};
    },
    async getBillingData(isLoadingMore = false) {
      try {
        if (Object.keys(this.billingData).length > 0 && !isLoadingMore) {
          (this.billingData as any) = {};
        }
        this.showCancellationReasonError = false;
        this.loadingText = "Fetching billing data. Please wait...";
        this.showAlert = false;
        this.policyData = this.form;
        this.policyData.policyNumber = this.doPadPolicyNumber(
          this.policyData.policyNumber
        );
        if (!this.showAlert) {
          setTimeout(() => {
            this.loadingText = `We're still searching for policy ${this.form.companyPrefix} - ${this.form.policyNumber} Billing Info. Please don't leave us 😭`;
          }, 6000);
        }
        const payload = {
          ...this.form,
          Summary: this.billingData?.Summary,
          Invoice: this.billingData?.Invoice
        };
        const response = await this.getBillingInfo(payload);

        if (response) {
          const { Trans, Summary, Invoice } = response;
          const hasError = (obj: any) => obj && "error" in obj;
          if (hasError(Trans) || hasError(Summary) || hasError(Invoice)) {
            this.showAlert = true;
            this.errorMessage = [Summary, Trans, Invoice]
              .filter(hasError)
              .map(obj => obj.error.join("\n"))
              .join("\n");
          } else {
            this.billingData = {
              Summary: Summary ? Summary : this.billingData.Summary,
              Invoice: Invoice ? Invoice : this.billingData.Invoice,
              Trans: Trans ? Trans : this.billingData.Trans
            };
            if (this.form.billingType === "summary") {
              if (this.billingData.Summary.CancelReason == "12") {
                this.toggleInquiry();
                this.inquiryIsClosed = false;
                this.showAlert = true;
                this.alertType = "warning";
                this.showCancellationReasonError = true;
              }
              this.loadMore("invoice");
            } else if (this.form.billingType === "invoice" && this.isLapsed) {
              this.loadMore("trans");
            }
          }
        } else {
          this.errorMessage =
            "Error fetching billing information. Try again later";
          this.showAlert = true;
        }
      } catch (error) {
        const responseError = error.message;
        let message = Array.isArray(responseError)
          ? responseError.join("\n")
          : responseError ||
            "Error fetching billing information. Try again later";

        if (typeof responseError === "object" && responseError !== null) {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              message = responseError[key];
              message = message.join("\n");
            }
          }
        }
        this.errorMessage = message;
        this.showAlert = true;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async printBillingData() {
      const newTab = window.open(
        `${getOrigin()}/loading-preview/noMessage`,
        "_blank"
      );
      try {
        this.loadingText = "Printing billing data. Please wait...";
        this.form.billingType = "summary";
        const billingInfo = {
          ...this.billingData,
          ...this.policyData
        };
        const fileURL = await this.printBillingInfo(billingInfo);
        if (fileURL) {
          const url = makeBlobUrlFromBase64(fileURL);
          if (newTab) {
            newTab.location.href = url;
          }
        }
      } catch (error) {
        newTab?.close();
        this.$appNotifyError(
          "Error printing billing information. Try again later"
        );
        this.$bugSnagClient.notify(error);
      }
    },
    onToolbarItemSelected(action: string): void {
      set(this.form, "site", getSite(this.form.companyNumber));
      switch (action) {
        case "viewBillingInfo":
          this.form.billingType = "summary";
          this.getBillingData();
          break;
        case "viewSendingXML":
          break;
        case "viewResponseXML":
          break;
        case "print":
          this.printBillingData();
          break;
        case "payBill":
          this.$router.push({
            path: "/quickBillPay",
            query: {
              prefix: this.form.companyPrefix,
              policyNumber: this.form.policyNumber.toString(),
              companyNumber: this.form.companyNumber.toString(),
              effectiveDate: this.billingData.Summary.TermEffectiveDt
            }
          });
          break;
        case "cancel":
          this.$router.push("/").catch(() => {});
          break;
      }
    },
    getFormData(data: any) {
      this.form = data;
    },
    removeDashes(value: string) {
      return convertDashToSpace(value);
    },
    doPadPolicyNumber(policyNumber: string): string {
      return padPolicyNumber(policyNumber);
    },
    doFormatAgencyCode(value: string): string {
      return formatAgencyCode(value);
    },
    toggleInquiry() {
      this.inquiryIsClosed = !this.inquiryIsClosed;
    },
    setLoadingText() {
      const baseString = `We're still searching for policy ${this.form.companyPrefix} - ${this.form.policyNumber} Billing Info. Please don't leave us 😭..`;
      const commonString = "Still searching...";
      const loadingTexts = [
        baseString,
        `Still here. ${commonString}`,
        `Kinda a slow day today. ${commonString}`
      ];

      let randomLoadingText;
      const index = Math.floor(Math.random() * loadingTexts.length);
      randomLoadingText = loadingTexts[index + 1];

      this.loadingText = randomLoadingText;
    },
    loadMore(billingType: Form["billingType"]): void {
      set(this.form, "billingType", billingType);
      this.getBillingData(true);
    }
  },
  computed: {
    ...billingChangeRequestMapState(["makingApiRequest"]),
    ...authMapGetters(["getCurrentUser"]),
    primaryToolbar(): any {
      return {
        text: "View Billing Information",
        key: "viewBillingInfo",
        loading: this.makingApiRequest,
        disabled: this.disableButtons
      };
    },
    secondaryToolbar(): any {
      return this.userEditActions.length
        ? {
            text: "Actions",
            key: "actions",
            subItems: this.userEditActions
          }
        : {};
    },
    getCurrentAmtDue(): string {
      const currentAmtDue = get(this.billingData, "Summary.CurrentAmtDue", "");
      return currentAmtDue ? formatCurrencyFloat(currentAmtDue) : currentAmtDue;
    },
    getLastPaymentAmt(): string {
      const lastPaymentAmt: string = get(
        this.billingData,
        "Summary.LastPaymentAmt",
        ""
      );
      return lastPaymentAmt
        ? formatCurrencyFloat(lastPaymentAmt)
        : lastPaymentAmt;
    },
    billedTo(): string {
      return get(this.billingData, "Invoice.BilledTo", "");
    },
    invoiceAsOf(): string {
      return get(this.billingData, "Invoice.InvoiceDt", "");
    },
    getPaymentsCredits(): string {
      return formatCurrencyFloat(
        get(this.billingData, "Summary.TotalPaid", "")
      );
    },
    getBalance(): string {
      const balance: string = get(this.billingData, "Summary.TotalAmtDue", "");
      return balance ? formatCurrencyFloat(balance) : balance;
    },
    getTermPremium(): string {
      const termPremium: string = get(
        this.billingData,
        "Summary.TermPremium",
        ""
      );
      return termPremium ? formatCurrencyFloat(termPremium) : termPremium;
    },
    userEditActions(): any[] {
      const actions = [
        {
          title: "Cancel",
          command: "cancel"
        }
      ];

      if (this.getCurrentUser?.role === "admin") {
        actions.push(
          {
            title: "View Sending XML",
            command: "viewSendingXML"
          },
          {
            title: "View Response XML",
            command: "viewResponseXML"
          }
        );
      }
      if (Object.keys(this.billingData).length) {
        actions.push(
          {
            title: "Print",
            command: "print"
          },
          {
            title: "Pay bill",
            command: "payBill"
          }
        );
      }

      return actions;
    },
    isLapsed(): Boolean {
      return Boolean(
        get(this.billingData, "Summary.PolicyStatus") === "L" &&
          (get(this.billingData, "Summary.CancelReason") === "10" ||
            get(this.billingData, "Summary.CancelReason") === "12" ||
            get(this.billingData, "Summary.CancelReason") === "13" ||
            get(this.billingData, "Summary.CancelReason") === "14")
      );
    },
    policyFairPlanFeeAmt(): string {
      return get(this.billingData, "Trans.policyFairPlanFeeAmt", "");
    },
    getData(): any {
      return {
        attachments: [],
        sections: [
          {
            sectionTitle: "",
            showSection: Boolean(this.policyFairPlanFeeAmt),
            showDivider: false,
            sectionColumnSize: "3",
            information: [
              {
                informationColumnSize: "1",
                key: "Fair Plan Assessment Fee",
                value: formatCurrencyFloat(this.policyFairPlanFeeAmt)
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: this.isLapsed,
            showDivider: false,
            sectionColumnSize: "3",
            information: [
              {
                informationColumnSize: "1",
                key: "Reason",
                value: get(this.billingData, "Summary.CancelReasonCd", "")
              },
              {
                informationColumnSize: "1",
                key: "Cancellation Date",
                value: get(
                  this.billingData,
                  "Summary.ComputedCancellationDt",
                  ""
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection:
              Boolean(get(this.billingData, "Trans.Premium_Fees", "")) ||
              Boolean(get(this.billingData, "Trans.Premium_Policy_Fees", "")) ||
              Boolean(get(this.billingData, "Trans.CancelMethod", "")),
            showDivider: false,
            sectionColumnSize: "3",
            information: [
              {
                informationColumnSize: "1",
                key: "Return Premium",
                class: "flex md:justify-center",
                value: formatCurrencyCompactLong(
                  get(this.billingData, "Trans.Premium_Fees", "")
                )
              },
              {
                informationColumnSize: "1",
                key: "Return Policy Fees",
                class: "flex md:justify-end",
                value: formatCurrencyCompactLong(
                  get(this.billingData, "Trans.Premium_Policy_Fees", "")
                )
              },
              {
                informationColumnSize: "1",
                key: "Cancellation Method",
                class: "flex md:justify-center",
                value: get(this.billingData, "Trans.CancelMethod", "")
              }
            ]
          },
          {
            sectionTitle: "",
            showSection:
              Boolean(get(this.billingData, "Trans.TransEffectiveDt", "")) ||
              Boolean(get(this.billingData, "Trans.ReAmt", "")),
            showDivider: false,
            sectionColumnSize: "3",
            information: [
              {
                informationColumnSize: "1",
                key: "Check Issue Date",
                class: "flex md:justify-end",
                value: get(this.billingData, "Trans.TransEffectiveDt", "")
              },
              {
                informationColumnSize: "2",
                key: "Refund Amount",
                value: formatCurrencyFloat(
                  get(this.billingData, "Trans.ReAmt", "")
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: get(this.billingData, "Summary.PolicyStatus") === "C",
            showDivider: false,
            sectionColumnSize: "3",
            information: [
              {
                informationColumnSize: "1",
                key: "Date Received",
                value: get(this.billingData, "Summary.LastPaymentDt")
              },
              {
                informationColumnSize: "1",
                key: "Check Number",
                class: "flex md:justify-center",
                value: get(this.billingData, "Summary.LastPaymentCheck")
              },
              {
                informationColumnSize: "1",
                key: "Policy Will LAPSE Unless PAST DUE AMOUNT is Received by",
                class: "flex md:justify-end",
                value: get(this.billingData, "Summary.ComputedCancellationDt")
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: get(this.billingData, "Summary.PolicyStatus") === "C",
            showDivider: false,
            sectionColumnSize: "3",
            information: [
              {
                informationColumnSize: "1",
                key: "Past Due Amount",
                value: formatCurrencyFloat(
                  get(this.billingData, "Summary.CurrentAmtDue")
                )
              },
              {
                informationColumnSize: "1",
                key: "Last Payment Amount",
                class: "flex md:justify-center",
                value: formatCurrencyFloat(
                  get(this.billingData, "Summary.LastPaymentAmt")
                )
              },
              {
                informationColumnSize: "1",
                key: "Balance",
                class: "flex md:justify-end",
                value: this.getBalance
              }
            ]
          }
        ]
      };
    },
    disableButtons(): boolean {
      return (
        !this.form.companyPrefix ||
        !this.form.companyNumber ||
        !this.form.policyNumber
      );
    },
    shouldToggleInquiry(): boolean {
      return this.inquiryIsClosed;
    }
  }
});
