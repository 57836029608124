
import {
  companiesMapActions,
  companiesMapGetters
} from "@/store/modules/companies";
import Vue from "vue";
import { getPropertyChangeRequestDefaults } from "@/helpers/getPropertyChangeRequestDefaults";
import {
  propertyChangeRequestMapActions,
  propertyChangeRequestMapGetters,
  propertyChangeRequestMapState
} from "@/store/modules/propertychangerequest";
import { objectDifference } from "@/helpers";
import { validateFields } from "@/helpers";
import { get } from "lodash";
import { getUserCompanies, getCompanyPrefix } from "@/helpers/companies";
import { getPHPAPIFormattedDate } from "@/helpers/dateProcessor";
import { padPolicyNumber } from "@/helpers/generalHelpers";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

export default Vue.extend({
  name: "PolicyInformation",
  components: {
    CustomAlert
  },
  props: {
    policyInfo: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    effectiveDateLabel: {
      type: String,
      default: "Effective date of change"
    },
    isEditingMode: {
      type: Boolean,
      default: false
    },
    isChangeRequest: {
      type: Boolean,
      default: false
    },
    changeRequestType: {
      type: String
    }
  },
  data() {
    return {
      companyPrefixOption: [] as any,
      companyIds: [],
      hasValidCredentials: false,
      name: {
        firstName: "",
        lastName: ""
      },
      userCompanies: getUserCompanies("policyInfo"),
      loading: false,
      showAlert: false,
      errorMessage: "",
      formIsValid: false
    };
  },
  created() {
    this.getCompanies({ query: {}, drop_store: true });
  },
  methods: {
    ...companiesMapActions(["getCompanies"]),
    ...propertyChangeRequestMapActions(["policyNumberInquiry"]),
    updateIsValid() {
      this.hasValidCredentials = this.$refs
        ? validateFields(this.$refs)
        : false;
    },
    handleValidationChanged($event: string[]) {
      this.formIsValid = $event && $event.length === 0;
    },
    checkIfEmptyFields() {
      if (
        !get(this.policyInfo, "effectiveDate") ||
        this.policyInfo.effectiveDate === "" ||
        this.policyInfo.effectiveDate === undefined ||
        this.policyInfo.companyNum === "" ||
        this.policyInfo.companyNum === undefined ||
        this.policyInfo.policyPrefix === "" ||
        this.policyInfo.policyPrefix === undefined ||
        this.policyInfo.policyNumber === "" ||
        this.policyInfo.policyNumber === undefined
      ) {
        return true;
      }
      return false;
    },
    doPadPolicyNumber(policyNumber: string): void {
      const processedPolicyNumber = padPolicyNumber(policyNumber);
      this.$emit("paddedPolicyNumber", processedPolicyNumber);
    },
    async preLookUpData() {
      if (this.makingApiRequest) {
        return;
      }
      this.lookUpData();
    },
    async lookUpData(): Promise<void> {
      try {
        this.showAlert = false;
        this.loading = true;
        this.$emit("setLoadingText", "Getting Insured Info. Please wait");
        const response = await this.policyNumberInquiry({
          policyNumber: this.policyInfo.policyNumber,
          companyPrefix: this.policyInfo.policyPrefix,
          effectiveDate: this.policyInfo.effectiveDate,
          companyNumber: this.policyInfo.companyNum,
          isChangeRequest: this.isChangeRequest
        });
        if (response.data && response.data.length !== 0) {
          const {
            GivenName,
            Surname,
            isExempted,
            ActualCancellationDt,
            isLapsed,
            PolicyNumber
          } = response.data;
          if (
            this.isChangeRequest &&
            this.changeRequestType &&
            this.changeRequestType !== "cancellationChangeRequest" &&
            isLapsed
          ) {
            this.errorMessage =
              "Policy is Lapsed. For additional information, call your Underwriter.";
            this.showAlert = true;
          } else {
            const {
              fiservCompanyNumber = this.policyInfo.companyNum
            } = response;
            this.$emit("sendBackInsuredInfo", {
              firstName: GivenName,
              lastName: Surname,
              isExempted: isExempted,
              isLapsed,
              ActualCancellationDt: ActualCancellationDt,
              PolicyNumber: PolicyNumber,
              actualCompanyNumber: fiservCompanyNumber,
              changeRequestMade: true,
              changeRequestType: this.changeRequestType
            });

            this.$emit("sendBackInsuredMailingAndLocAddress", [
              {
                houseNumber: response?.data?.StreetNumber,
                streetName: response.data?.StreetName,
                city: response?.data?.City,
                zipCode: response?.data?.Zip,
                state: response?.data?.State
              },
              {
                houseNumber: response?.data?.LocStreetNumber,
                streetName: response?.data?.LocStreetName,
                city: response?.data?.LocCity,
                zipCode: response?.data?.LocZip,
                state: response?.data?.LocState
              }
            ]);
            this.$emit("updateChangeRequestData", {
              fieldKey: "agencyCode",
              value: response?.data?.AgentCode
            });
            this.$emit("updateChangeRequestData", {
              fieldKey: "agencyName",
              value: this.formatAgencyName(response?.data?.AgencyName)
            });
            this.$emit("updateChangeRequestData", {
              fieldKey: "companyNum",
              value: fiservCompanyNumber
            });
          }
        } else {
          this.errorMessage = "Error getting insured info. Try again later.";
          this.showAlert = true;
        }
      } catch (error) {
        const errorObject = error as Error;
        const errorMessage = errorObject.message;
        if (errorObject && errorMessage) {
          if (typeof errorMessage === "string") {
            this.errorMessage = errorMessage;
          } else if (typeof errorMessage === "object") {
            const messageObject = errorMessage as Record<string, string[]>;
            for (const message in messageObject) {
              const [errorString] = messageObject[message];
              this.errorMessage = errorString;
            }
          }
        }
        this.showAlert = true;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.$emit("setLoadingText", "");
      }
    },
    formatAgencyName(agencyName: string) {
      if (agencyName) {
        const index = agencyName.indexOf(":");
        return agencyName.slice(index + 2);
      } else {
        return "N/A";
      }
    },
    makeHumanReadableDate(date: string) {
      return getPHPAPIFormattedDate(new Date(date));
    }
  },
  computed: {
    ...companiesMapGetters(["companies"]),
    ...propertyChangeRequestMapState(["makingApiRequest"]),
    ...propertyChangeRequestMapGetters(["getEditingPropertyChangeRequest"]),
    updatedFields(): any {
      const edited = this.getEditingPropertyChangeRequest;
      const original = getPropertyChangeRequestDefaults({});
      return edited && original ? objectDifference(edited, original) : {};
    }
  },
  watch: {
    updatedFields: {
      handler() {
        this.updateIsValid();
        if (
          this.policyInfo.companyNum &&
          this.policyInfo.policyPrefix &&
          this.policyInfo.effectiveDate &&
          this.hasValidCredentials
        ) {
          this.$emit("policyInfoFieldValid", true);
        } else {
          this.$emit("policyInfoFieldValid", false);
        }
      },
      immediate: true
    },
    "policyInfo.companyNum": {
      handler(newValue: any, oldValue) {
        if (oldValue === 29 && newValue === 30) {
          this.userCompanies = getUserCompanies("policyInfo", undefined, false);
        }
        (this.companyPrefixOption as any) = getCompanyPrefix(
          this.userCompanies,
          newValue
        );
        this.policyInfo.companyPrefix = "";
      }
    }
  }
});
