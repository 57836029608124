var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 createAgentApplication--main"},[_c('div',{staticClass:"sm:mx-auto sm:w-full sm:max-w-md mb-6"},[_c('router-link',{attrs:{"to":"/login"}},[_c('img',{staticClass:"mx-auto h-12 w-auto",attrs:{"src":_vm.logoPath,"alt":"Workflow"}})])],1),_c('PageWrapDefault',{attrs:{"loading":_vm.loading,"pageTitle":'Become An Atlas Agent',"showBreadcrumbs":false,"topActionButtonPrimary":{
      text: 'Submit Application',
      key: 'submitApplication',
      disabled: !_vm.hasValidApplicationData,
      loading: _vm.makingApiRequest
    },"topActionButtonSecondary":{
      text: 'Cancel',
      key: 'cancel'
    }},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[(_vm.editing)?_c('form-builder',{ref:"formElement",staticClass:"mt-10",attrs:{"schemas":_vm.agentApplicationFormFields,"formValues":_vm.editing,"dynamicOptions":{},"buttons":[]},on:{"formFieldChanged":_vm.formFieldChangeHandler,"validationChanged":function($event){_vm.validationData = $event},"formFieldBlur":_vm.formFieldBlur}}):_vm._e(),_c('el-row',[_c('el-col',[_c('p',{staticClass:"font-medium flex items-center pt-3 pb-3"},[_vm._v(" In addition to the application, we will require current License information, evidence of current Errors & Omission insurance with a minimum $1,000,000 limit and a current, signed W-9 form. ")])])],1),_c('saveChanges',{attrs:{"primaryButtonText":"Yes","title":"You have not submitted your form. Complete filling and and submit form?"},on:{"clickedSave":_vm.beforeRouteLeaveSave,"clickedDiscard":_vm.beforeRouteLeaveDiscardChanges}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }