
import Vue from "vue";
import { format } from "date-fns";
import { get } from "lodash";
import { getOrigin } from "@/helpers";
import { userMapActions } from "@/store/modules/user";
import { formatQuoteUpdateText } from "@/helpers/quoteActivitiesFormatter";
import { IPolicyInfo } from "@/helpers/storageFormBaseInterface";
import { formatDate } from "@/helpers/dateProcessor";

const ActivitiesTimelineMixin: any = Vue.extend({
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  components: {
    TabMenu: () => import("@/components/TabMenu/TabMenu.vue"),
    ActivitiesView: () =>
      import("@/components/ActivitiesView/ActivitiesView.vue"),
    ActivitiesTimelineItem: () =>
      import("@/components/ActivitiesView/ActivitiesTimelineItem.vue")
  },
  data: function() {
    return {
      content: "",
      icon: "",
      type: "",
      link: {},
      color: "",
      size: "large",
      linkColor: "#409EFF",
      linkColorDark: "#034b9d"
    };
  },
  methods: {
    ...userMapActions(["getUser"]),
    displayChangeRequestData(policyInfo: IPolicyInfo) {
      return `<div>
      <p>Effective Date: ${formatDate(policyInfo?.effectiveDate)}</p>
      <p>Policy Number: ${policyInfo?.policyNumber}</p>
      <p>Policy Prefix: ${policyInfo?.policyPrefix}</p>
      <p>Company Number: ${policyInfo?.companyNum}</p>
      <p>Agency Name: ${policyInfo?.agencyName}</p>
      <p>Agency Code: ${policyInfo?.agencyCode}</p>
      <div>`;
    },
    formatDate(date_str: string) {
      return format(new Date(date_str), "MMMM d, y");
    },
    baseLink(userId: string) {
      return `${getOrigin()}/admin/users/${userId}/edit`;
    },
    generateReadableGeneric({
      content,
      icon,
      type,
      link
    }: {
      content: string;
      icon: any;
      type: string;
      link: string;
    }) {
      this.content = `{userName} ${content}`;
      this.icon = icon;
      this.type = type;
      this.link = link ? link : this.accountTypeFormatted;
    },
    generateReadableFailGeneric({
      content,
      icon,
      link,
      extraData
    }: {
      content: string;
      icon: any;
      link: string;
      extraData?: Record<string, any>;
    }) {
      this.content = `<p>{userName} ${content}</p><p>Reason: ${
        this.failureReason ? this.failureReason : "N/A"
      }`;
      if (extraData && Object.keys(extraData).length) {
        for (const key in extraData) {
          this.content += `<p>${key}: ${extraData[key]}</p>`;
        }
      }
      this.icon = icon;
      this.type = "danger";
      this.link = link ? link : this.accountTypeFormatted;
    },
    generateReadableUpdate() {
      let contentHtml = ``;
      for (let update of this.updates) {
        if (update.field.includes("attachments")) {
          contentHtml =
            contentHtml + `<p>{userName} uploaded an attachment</p>`;
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, update);
        }
      }
      this.setReadableUpdateProps(contentHtml);
    },
    setReadableUpdateProps(content: string) {
      this.content = content;
      this.icon = "el-icon-edit";
      this.link = this.accountTypeFormatted;
    },
    generateRegularActivityString(
      contentHtml: string,
      update: any,
      userLink?: string
    ) {
      const output = formatQuoteUpdateText({
        linkColor: this.linkColor,
        activity: this.activity,
        field: update.field.replace("data.", ""),
        requiredData:
          update.update || typeof update.update === "boolean"
            ? update.update
            : "N/A",
        userLink
      });
      return contentHtml + output;
    }
  },
  computed: {
    formattedActivityText(): any {
      const linksArray = Object.keys(this.link);
      let content = this.content;
      if (linksArray.length) {
        linksArray.map((linkKey: any) => {
          const link = (this.link as any)[linkKey];
          if (link) {
            const { name, href } = link;
            let linkNode;
            if (href) {
              linkNode = `<a style="color: ${this.linkColor}" href="${href ||
                "#"}"> ${name} </a>`;
            } else if (name) {
              linkNode = `<span> ${name} </span>`;
            } else {
              linkNode = "";
            }
            const reg = new RegExp(`{${linkKey}}`, "g");
            content = content.replace(reg, linkNode);
          }
        });
      }
      return content;
    },
    data(): any {
      return this.activity.data;
    },
    updates(): any {
      return get(this.data, "updates");
    },
    deletes(): any {
      return get(this.data, "deletes", []);
    },
    failureReason(): any {
      return get(this.data, "reason");
    },
    accountTypeFormatted(): any {
      let nameToDisplay = "";
      if (this.$getCurrentUser._id === this.activity.userId) {
        nameToDisplay = "You";
      } else {
        const fullName = get(this.activity.userData, "fullName", "") || "";
        const firstName = get(this.activity.userData, "firstName", "") || "";
        const lastName = get(this.activity.userData, "lastName", "") || "";
        nameToDisplay = `${firstName} ${lastName}`;
        if (fullName) {
          nameToDisplay = fullName;
        }
      }
      let link = {
        name: nameToDisplay,
        href: this.baseLink(this.activity.userId)
      };
      return { userName: link };
    }
  }
});
export default ActivitiesTimelineMixin;
