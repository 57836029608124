var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":true,"topActionButtonPrimary":{
    text: 'Create PPC',
    key: 'createPPC'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading || _vm.makingApiRequest),expression:"loading || makingApiRequest"}],attrs:{"hideAllFilters":false,"tableRowActions":_vm.tableRowActions,"element-loading-text":_vm.loadingText,"data":_vm.gbsHelp,"tableActions":_vm.tableActions,"addAdvancedFilter":false,"tableColumns":_vm.tableColumns,"hideToggleIcons":true,"totalRecords":_vm.getTotalRecords,"paginationLinks":_vm.getGbsHelpLinks},on:{"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewCallback,"table-row-action":_vm.handleResponseAction,"table-action":_vm.tableActionHandler}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No PPCs Found."},slot:"notFound"})],1),_c('ModalMessage',{attrs:{"name":"gbsHelpDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Company Deletion","description":"This will delete the company. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.deleteOneGbsHelp()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('gbsHelpDeleteModal')
      }
    ]}}),_c('ModalBase',{attrs:{"name":"ppcUploaderModal","size":"medium","clickToClose":false,"showClose":true,"position":"center","title":"Upload PPC"}},[_c('GbsHelpUploader',{attrs:{"showHeading":false},on:{"close":function($event){return _vm.$modal.hide('ppcUploaderModal')},"upload-success":_vm.uploadSuccess}})],1),_c('ModalMessage',{attrs:{"name":"companyNotFoundModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-yellow-600 text-6xl","title":"Companies not found.","description":_vm.companiesNotFoundMessage,"buttons":[
      {
        text: `Close`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.handleCloseCompanyNotFoundModal()
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }