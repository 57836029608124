
import Vue from "vue";
import { tableColumnsListFaq } from "./FaqTable";
import { ITableWrapperColumns } from "@/types";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { faqMapActions, faqMapGetters } from "@/store/modules/faq";
import { cloneDeep } from "lodash";
import { copyTextToClipboard, getOrigin } from "@/helpers";

interface IFAQData {
  tableActions: any[];
  errorMessage: string;
  isLoading: boolean;
  loading: boolean;
  successMessage: string;
  tableRowActions: any[];
  loadingText: string;
  faqId: string;
}

interface IActions {
  text: string;
  key: string;
  subItems: { title: string; command: string }[];
}

export default Vue.extend({
  name: "listFaqs",
  components: {
    NoResultsFound
  },
  data(): IFAQData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      tableActions: [],
      loading: false,
      faqId: "",
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        { label: "Copy Link", value: "copy-link", faIcon: "clipboard" },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        }
      ],
      loadingText: ""
    };
  },
  methods: {
    ...faqMapActions(["getFAQs", "deleteFaq"]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "createFaq":
          this.$router.push(`/admin/faq/create`);
          break;
        case "FAQ-activities":
          this.$router.push(`/admin/faqs/activities`).catch(() => {});
          break;
      }
    },
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Loading FAQs. Please wait...";
        this.$emit("scrollTop");
        await this.getFAQs({
          query: { ...queryObject }
        });
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },

    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/faq/${_id}`).catch(() => {});
    },
    async createCallback(): Promise<any> {
      this.$router.push(`/admin/faq/create`);
    },

    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          this.$router.push(`/admin/faq/${data._id}`);
          break;
        case "copy-link": {
          const stringifyPlatformIds = data.atlasPlatformIds.join(",");
          const url = `${getOrigin()}/FAQ?platforms=${stringifyPlatformIds}&question=${
            data.question
          }`;
          try {
            await copyTextToClipboard(encodeURI(url));
            this.$appNotifySuccess("Copied FAQ link");
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          }

          break;
        }
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneFaq(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting FAQ. Please wait...";
        await this.deleteFaq(this.faqId);
        this.$modal.hide("faqDeleteModal");
        this.$appNotifySuccess("FAQ Successfully Deleted");
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    deleteCallback(faqId: any): void {
      this.faqId = faqId;
      this.$modal.show("faqDeleteModal");
    }
  },
  computed: {
    ...faqMapGetters(["faqs", "getTotalRecords", "getFaqsLinks"]),
    tableColumns(): ITableWrapperColumns {
      return cloneDeep(tableColumnsListFaq);
    },
    topButtonSecondaryItems(): IActions {
      const primary = {
        text: "Actions",
        key: "actions"
      };
      const otherUsersOptions: IActions["subItems"] = [
        {
          title: "All Activities",
          command: "FAQ-activities"
        }
      ];

      return {
        ...primary,
        subItems: otherUsersOptions
      };
    }
  }
});
