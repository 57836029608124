var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.error,"successMessage":_vm.message,"showSearch":false,"topActionButtonPrimary":_vm.saveButton,"topActionButtonSecondary":_vm.actionButtonSecondary,"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.error = ''}}},[(!_vm.resourceNotFound)?_c('div',[_c('UserMenu',{staticClass:"mb-12",attrs:{"activeIndex":_vm.activeIndex}}),(_vm.didInit)?_c('router-view',{ref:"singleUser",on:{"formIsValid":function($event){_vm.disablePrimaryButton = $event},"setMessage":function($event){_vm.message = $event},"setError":function($event){_vm.error = $event}}}):_vm._e()],1):_c('ResourceNotFound',{attrs:{"title":_vm.resourceNotFoundMsg,"actionButtonTitle":"Back to users","actionRedirectPath":_vm.usersRoute}}),_c('ModalMessage',{attrs:{"name":"restoreUserModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Restore","description":"This will restore the deleted user. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        disabled: true,
        click: () => _vm.restoreDeletedUser()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('restoreUserModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }