var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"pageTitle":`${_vm.company && _vm.company.companyName ? _vm.company.companyName : ''} `,"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"element-loading-text":_vm.loadingText,"showSearch":false,"topActionButtonPrimary":{
    text: 'Save',
    key: 'save',
    disabled: !_vm.formIsValid,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancelEditingCompany',
    disabled: _vm.isLoading
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[(!_vm.resourceNotFound)?[(_vm.$getCurrentUser && _vm.$getCurrentUser.role == 'admin')?_c('TabMenu',{staticClass:"mb-12",attrs:{"menuItems":_vm.menuItems,"selectedTabKey":_vm.selectedTabKey},on:{"tabClicked":_vm.onTabItemClick}}):_vm._e(),(!_vm.showActivities)?_c('div',[_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between"},[_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Edit Company ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Modify the company details below. ")])])]),(_vm.editing)?_c('form-builder',{ref:"formElement",attrs:{"schemas":_vm.companyFields,"formValues":_vm.editing,"dynamicOptions":{},"buttons":[]},on:{"formFieldChanged":_vm.formFieldChangeHandler,"validationChanged":() => {}}}):_vm._e(),(_vm.editing)?_c('div',{staticClass:"flex justify-end",class:[
          Object.keys(_vm.updatedFields).length > 0 ? 'justify-between' : ''
        ]},[_c('IAButton',{staticClass:"text-red-500",attrs:{"click":_vm.deleteCallback,"title":_vm.__getTextUcFirst('simple', 'deleteCompany'),"type":"text"}})],1):_vm._e()],1):(_vm.showActivities)?_c('ActivitiesView',{attrs:{"queryOverride":_vm.queryOverride},scopedSlots:_vm._u([{key:"default",fn:function({ activity }){return [_c('ActivitiesTimelineItem',{attrs:{"activity":activity}})]}}],null,false,3991501741)}):_vm._e(),_c('ModalMessage',{attrs:{"name":"companiesSingleDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Company Deletion","description":"This will delete the company. Continue?","showClose":true,"buttons":[
        {
          text: `Okay`,
          classList: ['bg-gray-100 text-gray-600 border-gray-300'],
          click: () => _vm.deleteOneCompany()
        },
        {
          text: `Cancel`,
          classList: ['bg-red-700'],
          click: () => _vm.$modal.hide('companiesSingleDeleteModal')
        }
      ]}}),_c('ModalMessage',{attrs:{"name":"companiesSingleEditModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":_vm.__getText('global', 'willSaveChangesTitle'),"description":_vm.__getText('global', 'willSaveChanges'),"showClose":true,"buttons":[
        {
          text: `Ok`,
          classList: ['bg-gray-100 text-gray-600 border-gray-300'],
          click: () => _vm.updateCallback()
        },
        {
          text: `Cancel`,
          classList: ['bg-red-700'],
          click: () => _vm.$modal.hide('companiesSingleEditModal')
        }
      ]}})]:_c('ResourceNotFound',{attrs:{"title":"Resource not found.","subtitle":"Sorry, the company you were looking for could not be found.","actionButtonTitle":"Back to companies","actionRedirectPath":"/admin/companies"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }