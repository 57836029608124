<template>
  <section
    class="flex items-center justify-center flex-col gap-8 sm:flex-row py-4 sm:py-8"
  >
    <!-- Clip Art -->
    <div class="flex items-center justify-center sm:h-96 sm:mr-8">
      <img
        class="w-100 h-100 object-cover pulse"
        :src="resourceNotFoundImage"
        alt="resource not found"
      />
    </div>
    <div class="sm:px-11">
      <!-- Title -->
      <div class="text-center sm:text-left">
        <h1 class="font-bold py-4 text-3xl sm:text-5xl">
          Resource Not Found
        </h1>
        <p class="font-medium text-md max-w-sm">
          {{ subtitle }}
        </p>
      </div>
      <!-- Buttons -->
      <div
        class="flex gap-4 justify-center sm:justify-start flex-wrap items-center my-4"
      >
        <router-link
          class="rounded block py-2 px-4 font-medium text-white bg-atlas-blue hover:bg-blue-900"
          :to="actionRedirectPath"
        >
          <span>
            <span class="ml-2">{{ actionButtonTitle }}</span>
          </span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { getSystemFilesURL } from "@/helpers";
import Vue from "vue";
export default Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: "Ooops! The resource you are looking for cannot be found."
    },
    subtitle: {
      type: String,
      required: false,
      default: "You may have the wrong url. Check and try again later"
    },
    actionButtonTitle: {
      type: String,
      required: false,
      default: "Back to homepage"
    },
    actionRedirectPath: {
      type: String,
      required: false,
      default: "/"
    }
  },
  data() {
    return {
      resourceNotFoundImage: ""
    };
  },
  async mounted() {
    try {
      const url = await getSystemFilesURL("resource-not-found");
      if (url) {
        this.resourceNotFoundImage = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  }
});
</script>
<style>
@keyframes pulse {
  50% {
    transform: scale(1.1);
  }
}
</style>
<style scoped>
.main-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77vh;
  width: 100%;
  flex-direction: column;
}

.main-icon {
  font-size: 121px;
}
.pulse {
  animation: pulse 2.2s 1 linear;
}
</style>
