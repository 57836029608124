
import { getSystemFilesURL } from "@/helpers";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      backgroundPatternImage: ""
    };
  },
  async mounted() {
    try {
      const url = await getSystemFilesURL("bg-pattern1");
      if (url) {
        this.backgroundPatternImage = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  }
});
