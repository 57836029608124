
import Vue from "vue";
import { removeEmptyFields } from "@/helpers";
import {
  agencyMapActions,
  agencyMapGetters,
  agencyMapMutations,
  agencyMapState
} from "@/store/modules/agency";
import {
  companiesMapActions,
  companiesMapGetters
} from "@/store/modules/companies";
import AgenciesMixin from "./AgenciesMixin.vue";
import { agencyStatus } from "@/helpers/selectOptions";
interface ICreateCompany {
  errorMessage: string;
  successMessage: string;
  isAdmin: boolean;
  isLoading: boolean;
  companyIds: any[];
  hasValidCredentials: boolean;
  statusOptions: any[];
  validationData: Record<string, any>;
  defaultPhoneValue: any;
}
export default Vue.extend({
  name: "createAgency",
  components: {
    AgencyCodePrefixPickerModal: () =>
      import("@/components/Agency/ListAgency/AgencyCodePrefixPickerModal.vue")
  },
  mixins: [AgenciesMixin],
  created() {
    this.SET_EDIT({ phone: this.defaultPhoneValue } as any);
    this.getCompanies({ query: {}, drop_store: true });
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  data(): Partial<ICreateCompany> {
    return {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      companyIds: [],
      hasValidCredentials: false,
      statusOptions: agencyStatus,
      validationData: {},
      defaultPhoneValue: {
        primary: "",
        secondary: ""
      }
    };
  },
  computed: {
    ...agencyMapGetters(["getEditingAgency"]),
    ...agencyMapState(["editing", "makingApiRequest", "agencies"]),
    ...companiesMapGetters(["companies"]),
    mapCompanyOptions(): any {
      return this.companies.map((company: any) => {
        return {
          label: company.companyName,
          value: company._id
        };
      });
    }
  },
  methods: {
    ...agencyMapActions(["createAgency"]),
    ...agencyMapMutations(["SET_EDIT"]),
    ...companiesMapActions(["getCompanies"]),
    async submit(): Promise<void> {
      try {
        if (this.formIsValid) {
          this.isLoading = true;
          const fieldData = this.editing;
          await this.createAgency(removeEmptyFields(fieldData as any)).then(
            () => {
              this.successMessage = "Agency created successfully";
            }
          );
          this.$router.replace("/admin/agencies").catch(() => {});
        } else {
          this.errorMessage = "Please Fill All Required Fields.";
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
      }
    },
    async createCallback(): Promise<any> {
      this.$router.push("/admin/agencies").catch(() => {});
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.submit();
          break;
        case "cancelAddingNewAgency":
          this.createCallback();
          break;
      }
    }
  }
});
