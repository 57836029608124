import { getSystemFilesURL } from "@/helpers";

export const billingTutorial = async () => {
  return [
    {
      title: "Billing Inquiry",
      steps: [
        {
          title: "Step 1",
          text: `To make a Billing Inquiry first select the "Billing Inquiry" option under the Policy Menu`,
          imgUrl: await getSystemFilesURL("billing-inquiry-navigation")
        },
        {
          title: "Step 2",
          text: `Select the company. Enter the policy prefix. Enter the policy number. After the fields have been filled. Click on the "View Billing Information" button to proceed`,
          imgUrl: await getSystemFilesURL("billing-inquiry-making-an-inquiry")
        },
        {
          title: "Step 3",
          text: `Welcome to the billing inquiry page! You can view the details of the inquiry below.`,
          imgUrl: await getSystemFilesURL("billing-inquiry")
        },
        {
          title: "Step 4",
          text: `There are a number of actions available for a billing inquiry. These can be accessed by clicking on the "Actions" dropdown. A menu containing the actions will then be displayed.You may:
            "Cancel" to go back
            "Print" a billing inquiry
            Proceed to "Pay Bill"
            View Sending XML (If Admin)
            View Receiving XML (If Admin)`,
          imgUrl: await getSystemFilesURL("billing-inquiry-using-actions")
        }
      ]
    },
    {
      title: "Bill Pay – Using Quick Pay",
      steps: [
        {
          title: "Step 1",
          text: `To use Quick Pay first select the "Bill Pay" option under the Policy Menu`,
          imgUrl: await getSystemFilesURL("bill-pay-navigation")
        },
        {
          title: "Step 2",
          text: `Welcome to the quick pay screen!
            Enter the effective date. Enter the policy prefix. Enter the policy number. After the fields have been filled. Click on the "Next Step" button to continue.Should you decide not to proceed. You may click the "Cancel" button. Note the helpline at the bottom. Please call if you have any questions or need any help.`,
          imgUrl: await getSystemFilesURL("bill-pay-using-quick-pay")
        },
        {
          title: "Step 3",
          text: `After clicking "Next Step" you will be brought to the "Verify Policy Info" screen. Ensure all information displayed in the read-only fields are as expected. Enter the current amount due. Select the payment method. Remember to acknowledge the use of a third party website by clicking the checkbox. You will only be able to proceed if all fields are properly filled out and valid. After you have finished filling the form, click the "Pay" button to begin the payment process. You may also click the "Cancel" button if you decide not to move forward with payment. If you would like to return to the previous page to make updates, click the "Previous" button`,
          imgUrl: await getSystemFilesURL("bill-pay-verifying-policy-info")
        },
        {
          title: "Step 4",
          text: `After clicking "Pay" this should open a secure payment pop-up to begin the payment process. Verify the amount to pay is right then click the "Review" button to continue (1). You may also click the "Cancel" button to discontinue the payments process. Click the edit button if you need to make any changes to the payment amount before you submit your payment.`,
          imgUrl: await getSystemFilesURL("bill-pay-reviewing-payment-amount")
        },
        {
          title: "Step 5",
          text: `If you do click the edit button, you will have the option to select the payment amount. After selecting an option. Click "OK" to save.`,
          imgUrl: await getSystemFilesURL("bill-pay-editing-amount")
        },
        {
          title: "Step 6.1",
          text: `Enter the account details and click "Review". All fields need to be filled properly.`,
          imgUrl: await getSystemFilesURL(
            "bill-pay-entering-billing-information-account"
          )
        },
        {
          title: "Step 6.2",
          text: `Ensure the credit card type is supported.`,
          imgUrl: await getSystemFilesURL(
            "bill-pay-entering-billing-information-credit-card"
          )
        },
        {
          title: "Step 7",
          text: `Verify the payment account details you have provided is correct and the amount to be paid is right. After verification, click the "Pay" button to initiate payment when ready`,
          imgUrl: await getSystemFilesURL(
            "bill-pay-verifying-amount-and-paying-bill"
          )
        },
        {
          title: "Step 8",
          text: `Congratulations! You have successfully completed a payment. Remember to back up a copy of the receipt via the options provided. You may backup by: (1) Printing a receipt using the "Print Receipt" button. (2) Downloading a receipt using the "Download Receipt" button`,
          imgUrl: await getSystemFilesURL("bill-pay-after-payment-actions")
        }
      ]
    }
  ];
};
