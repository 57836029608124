var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"padded-page-wrapper"},[_c('ModalBase',{attrs:{"name":"specialOtherVerbiageModal","size":"medium","clickToClose":false,"title":"Special Other Verbiage","showClose":true,"loading":_vm.makingApiRequest,"position":"center","bottomButtonSecondary":{
      key: 'cancel',
      label: 'Cancel'
    },"bottomButtonPrimary":{
      key: 'enter-code',
      label: 'Get/Enter Underwriter Approval Code'
    },"error":_vm.error},on:{"primaryButtonClick":_vm.primaryButtonClickHandler,"secondaryButtonClick":function($event){return _vm.$emit('close')},"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"px-12 text-md"},[_c('div',{staticClass:"flex flex-col justify-center items-center text-atlas-blue"},[_c('font-awesome-icon',{staticClass:"text-9xl",attrs:{"icon":['fal', 'exclamation-circle']}}),_c('p',{staticClass:"my-4 text-lg"},[_vm._v(" "+_vm._s(_vm.title ? _vm.title.toUpperCase() : _vm.title)+" ")])],1),_c('span',{staticClass:"text-base font-medium"},[_vm._v("Please follow the necessary steps: "),_c('br')]),_c('ol',{staticClass:"list-disc text-base mb-8"},[_c('li',[_c('b',[_vm._v("Step 1:")]),_vm._v(" "+_vm._s(_vm.underwriterMsg)+".")]),_c('li',[_c('b',[_vm._v("Step 2:")]),_vm._v(" Underwriter will assist in ordering and reviewing the A-Plus report. ")]),_c('li',[_c('b',[_vm._v("Step 3:")]),_vm._v("Inspection form and required photos, Professional Roofer Report, Hydrostatic water pressure test will need to be submitted to Underwriting for approval. ")])]),(_vm.creditScoreIndicatorFlag && _vm.creditScoreIndicatorFlag === 'D')?_c('span',{staticClass:"text-red-500 font-medium text-base"},[_vm._v("Reminder, you have no binding authority for this risk")]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }