
import Vue from "vue";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";

import { userMapActions, userMapGetters } from "@/store/modules/user";
import { ITableWrapperColumns } from "@/types";
import { tableColumnsListUsers } from "./TableColumns";
import { authMapGetters } from "@/store/modules/auth";
import * as selectOptions from "@/helpers/selectOptions";
import BannedOrRestrictedUsersUploader from "@/components/User/ListUsers/BannedOrRestrictedUsersUploader.vue";
import { cloneDeep, get } from "lodash";
import { FormBlock } from "@/components/FormBuilder/types";
import { restrictedUser } from "@/forms/admin/restrictUser";
import UsersMixin from "../UsersMixin.vue";
import { getUserRoleOptions, copyTextToClipboard } from "@/helpers";
import { getOrigin } from "@/helpers";

interface IListUsersData {
  loading: boolean;
  tableColumns: ITableWrapperColumns;
  uploadMode: string;
  errorMessage: string;
  successMessage: string;
  selectedDocuments: any;
  restrictData: { startDate: Date; endDate: Date };
  loadingText: string;
  currentTableShowing: string;
  queryParams: any;
  showingRestrictedUsers: boolean;
  usersToFetch: string;
  fields: FormBlock[];
  selectedRow: any;
  buttonClickType: string;
  userId: string;
}
interface IModalMessage {
  fullName: string;
  type: "ban" | "restrict" | "derestrict" | "unban" | "delete";
  extraData?: "ban" | "restriction";
}
export default Vue.extend({
  name: "list-users",
  mixins: [UsersMixin],
  components: {
    Exporter: () => import("@/components/Exporter/Exporter.vue"),
    RestrictUsersDialog: () => import("./RestrictUsersDialog.vue"),
    BannedOrRestrictedUsersUploader,
    NoResultsFound
  },
  props: {
    isPage: {
      type: Boolean,
      default: true,
      required: false
    },
    hideCreateButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSearchBar: {
      type: Boolean,
      required: false,
      default: false
    },
    queryOverride: {
      type: Object,
      required: false,
      default: () => ({})
    },
    emitOnAction: {
      type: Boolean,
      default: false
    },
    additionalBulkTableActions: {
      type: Array,
      default() {
        return [];
      }
    },
    additionalTableActions: {
      type: Array,
      default() {
        return [];
      }
    },
    fromAgentPicker: {
      type: Boolean,
      default: false
    }
  },
  data(): IListUsersData {
    return {
      loading: false,
      tableColumns: tableColumnsListUsers,
      uploadMode: "",
      errorMessage: "",
      successMessage: "",
      selectedDocuments: [],
      restrictData: { startDate: new Date(), endDate: new Date() },
      loadingText: "",
      currentTableShowing: "users",
      queryParams: "",
      showingRestrictedUsers: false,
      usersToFetch: "Restricted Users",
      fields: restrictedUser,
      selectedRow: [],
      buttonClickType: "",
      userId: ""
    };
  },
  methods: {
    ...userMapActions([
      "getUsers",
      "banUsers",
      "restoreUser",
      "restrictUsers",
      "deRestrictUsers"
    ]),
    goToUserProfile(dataReceived: any) {
      const { _id } = dataReceived;
      if (
        this.getCurrentUser &&
        (this.getCurrentUser.role == "agencyAdmin" ||
          this.getCurrentUser.role == "subAdmin")
      ) {
        return this.$router.push(`/agencyAdmin/users/${_id}`).catch(() => {});
      } else if (
        this.$isCurrentUserUnderwriter ||
        this.$isCurrentUserClaims ||
        this.$isCurrentUserBilling ||
        this.$isCurrentUserDataEntry
      ) {
        return this.$router.push(`/users/${_id}`).catch(() => {});
      }
      this.$router.push(`/admin/users/${_id}`).catch(() => {});
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.createCallback();
          break;
        case "upload-banned-agents":
          this.uploadBannedOrRestrictedUsers("banned");
          break;
        case "upload-restricted-agents":
          this.uploadBannedOrRestrictedUsers("restricted");
          break;
      }
    },
    tableActionHandler(action: string) {
      switch (action) {
        case "Upload Banned Agents":
          return this.uploadBannedOrRestrictedUsers("banned");
        case "Upload Restricted Agents":
          return this.uploadBannedOrRestrictedUsers("restricted");
        case "Upload Users":
          this.uploadBannedOrRestrictedUsers("users");
          return;
        case "Export Users":
          this.$modal.show("exportUsersModal");
          break;
        case "Show Restricted Users":
          this.loadRestrictedUsers();
          break;
        case "Show Users":
          this.showingRestrictedUsers = false;
          this.fetchPageData({});
          break;
        case "All Activities":
          this.$router.push(`/admin/users-activities`).catch(() => {});
          break;
        default:
          if (this.emitOnAction) {
            return this.$emit("tableAction", action);
          }
          break;
      }
    },
    uploadSuccess() {
      let query = {};
      if (this.uploadMode === "banned") query = { isBanned: true };
      else if (this.uploadMode === "restricted") {
        query = { "isRestricted.startDate__gt": new Date() };
      }
      this.fetchPageData(query);
    },
    async fetchPageData(queryObject: any): Promise<void> {
      if (queryObject.role__in && this.queryOverride.role__in) {
        this.queryOverride.role__in = queryObject.role__in;
      }

      // const gtagTimer = this.__gtagMeasureTiming(
      //   "fetchPageData",
      //   "users",
      //   "admin"
      // );
      try {
        this.loading = true;
        this.loadingText = "Fetching users. Please wait...";
        this.$emit("scrollTop");
        await this.getUsers({
          query: { ...queryObject, ...this.queryOverride }
        });
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        // gtagTimer.done();
        this.resetLoadingActivity();
      }
    },

    async viewCallback(dataReceived: any): Promise<any> {
      if (this.emitOnAction) {
        return this.$emit("tableRowClicked", dataReceived);
      }
      if (!this.fromAgentPicker) {
        this.goToUserProfile(dataReceived);
      }
    },
    async createCallback(): Promise<any> {
      if (
        this.getCurrentUser &&
        (this.getCurrentUser.role == "agencyAdmin" ||
          this.getCurrentUser.role == "subAdmin")
      ) {
        return this.$router.push("/agencyAdmin/users/new").catch(() => {});
      }
      this.$router.push("/admin/users/new").catch(() => {});
    },
    uploadBannedOrRestrictedUsers(purpose: string) {
      this.uploadMode = purpose;
      this.$modal.show("uploadBannedOrRestrictedUsersModal");
    },
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Ban Selected": {
          try {
            this.loading = true;
            this.loadingText = "Banning users. Please wait...";
            const userIds = this.selectedDocuments.map((doc: any) => doc._id);
            await this.banUsers(userIds);
            this.$appNotifySuccess("Selected Users Banned");
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          } finally {
            this.resetLoadingActivity();
          }
          break;
        }
        case "Restrict Selected": {
          try {
            await this.$router.replace({ query: undefined });
            this.$modal.show("restrictUsersModal");
            this.currentTableShowing = "restrict-users";
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        case "Derestrict Selected": {
          this.$modal.show("derestrictUsersModal");
          break;
        }
        default:
          if (this.emitOnAction) {
            return this.$emit("bulkAction", {
              action,
              selected: this.selectedDocuments
            });
          }
          break;
      }
    },
    async restrictSelectedUsers(restrictData: any) {
      try {
        this.loading = true;
        this.loadingText = "Restricting users. Please wait...";
        const userIds = this.selectedDocuments.map((doc: any) => doc._id);
        await this.restrictUsers({ userIds, restrictData });
        this.$appNotifySuccess("Selected Users Restricted");
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.hideRestrictedUsersModal();
        this.resetLoadingActivity();
      }
    },
    hideRestrictedUsersModal() {
      this.$modal.hide("restrictUsersModal");
      this.$router.replace({ query: { ...this.queryParams } });
      this.currentTableShowing = "users";
    },
    loadRestrictedUsers() {
      this.showingRestrictedUsers = true;
      this.fetchPageData({
        "isRestricted.startDate__lte": new Date(),
        "isRestricted.endDate__gte": new Date()
      });
    },
    async derestrictSelectedUsers() {
      try {
        this.loading = true;
        this.loadingText = "Reversing restricts on users. Please wait...";
        const userIds = this.selectedDocuments.map((doc: any) => doc._id);
        await this.deRestrictUsers(userIds);
        this.$appNotifySuccess("Selected User Restrictions Removed");
        this.$modal.hide("deRestrictUsersModal");
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.resetLoadingActivity();
      }
    },
    formatModalMessage(data: IModalMessage): string {
      const { fullName, type, extraData = "" } = data;
      const extraText = ["unban", "derestrict"].includes(type);
      return `This will ${
        extraText ? `reverse the ${extraData} on` : type
      } ${fullName}. Continue?`;
    },
    handleResponseAction(this: any, payload: any) {
      const { data, name } = payload;
      this.selectedRow = data;
      this.userId = data._id;
      const viewUserURL = `/profile/${this.userId}`;
      const responseAction = typeof name === "string" ? name : name();
      const fullName = data.fullName;
      if (responseAction === "Ban" && !data.isBanned) {
        this.modalTitle = "User Banning";
        this.modalDescription = this.formatModalMessage({
          fullName,
          type: "ban"
        });
        this.buttonClickType = "ban";
      }
      if (responseAction === "Ban" && data.isBanned) {
        this.modalTitle = "User Unbanning";
        this.modalDescription = this.formatModalMessage({
          fullName,
          type: "unban",
          extraData: "ban"
        });
        this.buttonClickType = "unban";
      }
      if (responseAction === "Restrict" && !data.isRestricted) {
        this.modalTitle = "User Rerestriction";
        this.modalDescription = this.formatModalMessage({
          fullName,
          type: "restrict"
        });
        this.buttonClickType = "restrict";
      }
      if (responseAction === "Restrict" && data.isRestricted) {
        this.modalTitle = "User Derestriction";
        this.modalDescription = this.formatModalMessage({
          fullName,
          type: "unban",
          extraData: "restriction"
        });
        this.buttonClickType = "derestrict";
      }
      if (responseAction === "restore" && data.deleted) {
        this.modalTitle = "Restore Deleted";
        this.modalDescription = this.formatModalMessage({
          fullName,
          type: "restore"
        });
        this.buttonClickType = "restore";
      }
      if (responseAction === "delete") {
        this.modalTitle = "User Deletion";
        this.modalDescription = this.formatModalMessage({
          fullName,
          type: "delete"
        });
        this.buttonClickType = "delete";
      }
      if (responseAction === "view") {
        return this.$router.push(viewUserURL);
      }

      if (responseAction === "copyProfileLink") {
        return copyTextToClipboard(`${getOrigin()}${viewUserURL}`)
          .then(() => {
            this.$appNotifySuccess("Profile link copied");
          })
          .catch(error => {
            this.bugSnagClient(error);
          });
      }
      this.$modal.show("showModal");
    },
    handleRestrictBanClick(this: any): void {
      switch (this.buttonClickType) {
        case "restrict":
          this.restrictSingleUser(this.userId);
          return;
        case "derestrict":
          this.deRestrictSingleUser(this.userId);
          return;
        case "ban":
          this.banSingleUser(this.userId);
          return;
        case "unban":
          this.unbanSingleUser(this.userId);
          return;
        case "delete":
          this.userDelete(this.userId);
          return;
        case "restore":
          this.restoreDeletedUser(this.userId);
          return;
        default:
          return;
      }
    },
    resetModalData(this: any) {
      this.$modal.hide("showModal");
      this.modalTitle = "";
      this.modalDescription = "";
      this.buttonClickType = "";
    },
    resetLoadingActivity() {
      this.loading = false;
      this.loadingText = "";
    }
  },
  computed: {
    ...userMapGetters(["getUsersFromStore", "getUserLinks", "getTotalRecords"]),
    ...authMapGetters(["getCurrentUser"]),
    banOrRestrictionLegend(): string {
      const restrictedLegend =
        "*CSV file must have the following columns: EMAIL, START_DATE, END_DATE";
      const bannedLegend = "*CSV file must have EMAIL header";
      const userLegend = this.__getText(
        "global",
        "bulkUserImportMessage"
      ) as string;
      if (this.uploadMode === "banned") return bannedLegend;
      else if (this.uploadMode === "restricted") return restrictedLegend;
      else if (this.uploadMode === "users") return userLegend;
      else return "";
    },
    uploadTitle(): string {
      return this.uploadMode === "users"
        ? "Upload Users"
        : "Upload Banned or Restricted Users";
    },
    banOrRestrictionUrl(): string {
      if (this.uploadMode === "banned") return "users/banned/upload";
      else if (this.uploadMode === "restricted") return "users/restrict/upload";
      else if (this.uploadMode === "users") return "users/bulk/upload";
      return "";
    },
    uploadTemplateCollection(): string {
      if (this.uploadMode === "banned") return "userBan";
      else if (this.uploadMode === "restricted") return "userRestriction";
      else if (this.uploadMode === "users") return "users";
      return "";
    },
    optionsBasedOnRole(): any {
      if (this.getCurrentUser && this.getCurrentUser.role == "admin") {
        return [
          "Upload Banned Agents",
          "Upload Restricted Agents",
          "Upload Users",
          "Export Users",
          "All Activities",
          this.showingRestrictedUsers ? "Show Users" : "Show Restricted Users"
        ].sort();
      } else {
        return [];
      }
    },
    tableActions(): any[] {
      const moreOptions = this.optionsBasedOnRole as any;
      let baseTableActions = cloneDeep(this.additionalTableActions) as any;
      if (moreOptions.length) {
        baseTableActions.push(...moreOptions);
      }
      return this.fromAgentPicker ? [] : baseTableActions;
    },
    bulkMenuActions(): any[] {
      const bulkMenuAction = [...this.additionalBulkTableActions];
      if (this.$isCurrentUserAdmin) {
        bulkMenuAction.push({ title: "Ban Selected", disabled: false });
        if (this.showingRestrictedUsers) {
          bulkMenuAction.push({
            title: "Derestrict Selected",
            disabled: false
          });
        } else {
          bulkMenuAction.push({
            title: "Restrict Selected",
            disabled: false
          });
        }
      }

      return this.fromAgentPicker ||
        this.$isCurrentUserUnderwriter ||
        this.$isCurrentUserClaims ||
        this.$isCurrentUserBilling ||
        this.$isCurrentUserDataEntry
        ? []
        : bulkMenuAction;
    },
    filters(): any[] {
      const role = get(this.$getCurrentUser, "role", "");
      let filters = [
        {
          field: "role",
          title: "Role",
          options: getUserRoleOptions(role)
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        }
      ];
      if (!this.fromAgentPicker) {
        const moreFilters = [
          {
            field: "status",
            title: "Status",
            options: selectOptions.userStatus
          },
          {
            field: "isBanned",
            title: "Banned",
            options: [
              { label: "Yes", value: "true" },
              { label: "No", value: "false" }
            ]
          }
        ];
        filters = [...filters, ...moreFilters];
      }
      if (["admin", "agencyAdmin"].includes(this.userRole)) {
        const moreFilters = [
          {
            field: "deleted",
            title: "Deleted",
            options: [
              { label: "Yes", value: "true" },
              { label: "No", value: "false" }
            ]
          }
        ];
        filters = [...filters, ...moreFilters];
      }

      return filters;
    },
    userRole(): string {
      return get(this.getCurrentUser, "role", "");
    },
    tableRowActionsData(): any {
      let tableRowActions: (
        | {
            label: (row: any) => "Ban" | "UnBan";
            value: (row: any) => "Ban" | "UnBan";
            faIcon: string;
            show: boolean;
            class?: undefined;
          }
        | {
            label: (row: any) => "Restrict" | "UnRestrict";
            value: (row: any) => "Restrict" | "UnRestrict";
            faIcon: string;
            show: boolean;
            class?: undefined;
          }
        | {
            label: string;
            value: string;
            faIcon: string;
            class?: string;
            show: ((row: any) => any) | boolean;
          }
      )[] = [];
      const showDelete = (row: any) => !row?.deleted;
      const showRestore = (row: any) => row?.deleted;
      if (this.$isCurrentUserAdmin) {
        const banStatus = (row: any) => (!row?.isBanned ? "Ban" : "UnBan");
        const restrictStatus = (row: any) => {
          return !row?.isRestricted ? "Restrict" : "UnRestrict";
        };
        tableRowActions = [
          {
            label: banStatus,
            value: banStatus,
            faIcon: "ban",
            show: true
          },
          {
            label: restrictStatus,
            value: restrictStatus,
            faIcon: "exclamation-triangle",
            show: true
          },
          {
            label: "View",
            value: "view",
            faIcon: "eye",
            show: true
          },
          {
            label: "Copy Link",
            value: "copyProfileLink",
            faIcon: "clipboard",
            show: true
          }
        ];
      }

      (this.$isCurrentUserAdmin || this.$isCurrentUserAgencyAdmin) &&
        tableRowActions.push(
          {
            label: "Delete",
            value: "delete",
            faIcon: "trash-alt",
            class: "bg-red-400 text-white hover:text-white hover:bg-red-500",
            show: showDelete
          },
          {
            label: "Restore",
            value: "restore",
            faIcon: "undo",
            class:
              "bg-green-400 text-white hover:text-white hover:bg-green-500",
            show: showRestore
          }
        );

      return tableRowActions;
    }
  },
  created() {
    this.queryParams = this.$route.query;
  }
});
