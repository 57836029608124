var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"topActionButtonPrimary":_vm.primaryTopActionButton,"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.toolbarSelectItem}},[_c('div',{staticClass:"mb-24"},[_c('UnderwriterInfo')],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('h1',{staticClass:"text-lg mb-4 font-medium"},[_vm._v("Territory Lookup")]),(_vm.lookupResults.length > 0)?_c('div',[(_vm.lookupResults.length > 1)?_c('p',{staticClass:"text-red-500 my-2 mt-4"},[_vm._v(" More than one territory found. Select one ")]):_vm._e(),_c('TerritoryLookupSelect',{key:_vm.territoryId,attrs:{"territories":_vm.lookupResults,"value":_vm.territoryId,"disabled":_vm.quoteHasBeenSubmitted || _vm.quoteHasBeenDeleted},on:{"change":_vm.terrChangedHandler}})],1):_c('div',[_c('span',[_vm._v(" Territory lookup information not available for "+_vm._s(_vm.editing.riskAddress.county)+". Click \"Back\" button to make changes ")]),_c('div',{staticClass:"w-20 pt-2"},[_c('router-link',{staticClass:"rounded block py-2 px-4 font-medium text-white bg-atlas-blue hover:bg-blue-900",attrs:{"to":_vm.previousUrl}},[_c('span',[_c('span',{staticClass:"ml-2"},[_vm._v("Back")])])])],1)])]),_c('ModalMessage',{attrs:{"name":"wind-inclusion-underwriter-modal","icon":['fal', 'question-circle'],"iconClass":"text-blue-600 text-6xl","title":"Wind Exclusion","description":"Signed Wind Exclusion MUST be mailed to your Underwriter.","buttons":[
      {
        text: 'Okay',
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => {
          this.$modal.hide('wind-inclusion-underwriter-modal');
          this.$router.push(this.nextUrl);
        }
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }