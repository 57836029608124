
import {
  quoteCountyValidationMapActions,
  quoteCountyValidationMapMutations
} from "@/store/modules/quoteCountyValidation";
import { SET_EDIT_FIELD } from "@/store/mutation-types";
import { objectDifference } from "@/helpers";
import VueWithMixins from "@/helpers/mixins";
import QuoteCountyValidationMixin from "./QuoteCountyValidationMixin";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
export default VueWithMixins(QuoteCountyValidationMixin).extend({
  name: "EditQuoteCountyValidation",
  mixins: [ActivitiesMixin],
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      disablePrimaryButton: true,
      loadingText: "",
      resourceNotFound: false,
      quoteCountyValidation: {}
    };
  },
  created() {
    this.SET_EDIT({} as any);
    this.getQuoteCountyValidation(this.quoteCountyValidationId)
      .then(data => {
        this.SET_EDIT(data);
        this.quoteCountyValidation = data;
      })
      .catch(error => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      });
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  computed: {
    quoteCountyValidationId(): string {
      return this.$route.params.quoteCountyValidationId;
    },
    updatedFields(): any {
      const edited = this.editing;
      const original = this.quoteCountyValidation;
      return edited && original
        ? objectDifference(edited, original, [
            "zipCode",
            "city",
            "state",
            "county",
            "exemptedAgencyCodes"
          ])
        : {};
    },
    queryOverride(): any {
      return {
        "data.quoteCountyValidationId": this.quoteCountyValidationId,
        activityType: "QuoteCountyValidationsActivity"
      };
    }
  },
  methods: {
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.disablePrimaryButton =
        !this.hasAtLeastOneRequiredField && !formIsValid;
    },
    async updateCallback(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Updating quote validation. Please wait...";
        await this.updateQuoteCountyValidation({
          id: this.quoteCountyValidationId,
          update: this.updatedFields
        });
        this.$appNotifySuccess("Quote Validation Updated Successfully");
        this.$router.replace("/admin/quoteCountyValidations");
      } catch (err) {
        this.errorMessage = "Operation specified is not supported";
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "updateNewQuoteCountyValidation":
          await this.updateCallback();
          break;
      }
    },
    ...quoteCountyValidationMapActions([
      "getQuoteCountyValidation",
      "updateQuoteCountyValidation"
    ]),
    ...quoteCountyValidationMapMutations(["SET_EDIT"]),
    ...quoteCountyValidationMapMutations({ editField: SET_EDIT_FIELD })
  }
});
