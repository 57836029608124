
import { getSystemFilesURL } from "@/helpers";
import Vue from "vue";

export default Vue.extend({
  name: "error404",
  data() {
    return {
      notFoundImage: ""
    };
  },
  async mounted() {
    try {
      const url = await getSystemFilesURL("404");
      if (url) {
        this.notFoundImage = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  },
  computed: {
    homeIcon(): string[] {
      return ["fal", "home"];
    },
    backIcon(): string[] {
      return ["fal", "arrow-left"];
    }
  }
});
