
import Vue from "vue";
import { getSystemFilesURL } from "@/helpers";
export default Vue.extend({
  name: "error401",
  data() {
    return {
      unauthorizedImage: ""
    };
  },
  async mounted() {
    try {
      const url = await getSystemFilesURL("401");
      if (url) {
        this.unauthorizedImage = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  },
  computed: {
    homeIcon(): string[] {
      return ["fal", "home"];
    }
  }
});
