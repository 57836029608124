import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "@/services/response_wrapper";

class UserService extends BaseCollectionService {
  constructor() {
    super("users");
  }

  public async getContactUsDetails(
    query: Record<string, any>
  ): Promise<ResponseWrapper> {
    const path = `/contactUs`;
    return await this.request(
      {
        method: "get",
        url: path,
        params: query
      },
      { useToken: "access" }
    );
  }
  public async getUserProfile(userId: string): Promise<ResponseWrapper> {
    const path = `/users/${userId}/profile`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }
  public async search(query: Record<string, any>): Promise<ResponseWrapper> {
    const path = `${this.url}-search`;
    return await this.request(
      {
        method: "get",
        url: path,
        params: query
      },
      { useToken: "access" }
    );
  }
  public async requestVerifyPhoneCode(
    phone: Record<string, any>
  ): Promise<ResponseWrapper> {
    const path = `${this.url}/verify/phone/request`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: phone
      },
      { useToken: "access" }
    );
  }
  public async verifyPhone(
    data: Record<string, any>
  ): Promise<ResponseWrapper> {
    const path = `${this.url}/verify/phone`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: data
      },
      { useToken: "access" }
    );
  }
  public async makeScreenViewActivity(
    data: Record<string, any>
  ): Promise<ResponseWrapper> {
    const path = `${this.url}/screen-view`;
    return await this.request(
      {
        method: "post",
        url: path,
        data
      },
      { useToken: "access" }
    );
  }
  public async banUsers(userIds: string[]): Promise<ResponseWrapper> {
    const path = `users/ban`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { userIds }
      },
      { useToken: "access" }
    );
  }
  public async restrictUsers(
    userIds: string[],
    restrictData: any
  ): Promise<ResponseWrapper> {
    const path = `users/restrict`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { userIds, ...restrictData }
      },
      { useToken: "access" }
    );
  }
  public async getUnderWriters(
    query: Record<string, any>
  ): Promise<ResponseWrapper> {
    const path = `/users/under-writers`;
    return await this.request(
      {
        method: "get",
        url: path,
        params: query
      },
      { useToken: "access" }
    );
  }
  public async deRestrictUsers(userIds: string[]): Promise<ResponseWrapper> {
    const path = `users/derestrict`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { userIds }
      },
      { useToken: "access" }
    );
  }
  public async restrictUser(
    userIds: string[],
    restrictData: any
  ): Promise<ResponseWrapper> {
    const path = `users/restrict`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { userIds, ...restrictData }
      },
      { useToken: "access" }
    );
  }
  public async derestrictUser(userIds: string[]): Promise<ResponseWrapper> {
    const path = `users/derestrict`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { userIds }
      },
      { useToken: "access" }
    );
  }
  public async banUser(userIds: string[]): Promise<ResponseWrapper> {
    const path = `users/ban`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { userIds }
      },
      { useToken: "access" }
    );
  }
  public async unbanUser(userIds: string[]): Promise<ResponseWrapper> {
    const path = `users/unban`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { userIds }
      },
      { useToken: "access" }
    );
  }
  public async restoreDeletedUser(userId: string): Promise<ResponseWrapper> {
    const path = `users/restore`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { userId }
      },
      { useToken: "access" }
    );
  }
  public async switchUserAgencyCode(
    userId: string,
    agentCode: string
  ): Promise<ResponseWrapper> {
    const path = `users/switchUserAgency/${userId}`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { agentCode }
      },
      { useToken: "access" }
    );
  }
}

export const userService = new UserService();
