
import {
  userMapActions,
  userMapGetters,
  userMapState
} from "@/store/modules/user";
import { cloneDeep } from "lodash";
import Vue from "vue";
import { capitalizeFirstLetter } from "../../helpers/generalHelpers";
export default Vue.extend({
  name: "search-select-underwriter",
  props: {
    value: {
      type: [Number, String, Array, Boolean, Object],
      default: ""
    },
    label: {
      type: String,
      default: "",
      required: false
    },
    preFetch: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "Type name, email or phone number to search underwriter"
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      selectedValue: ""
    };
  },
  mounted() {
    if (this.preFetch) {
      this.remoteMethod("");
    }

    this.selectedValue = this.value ? this.value.toString() : "";
  },
  watch: {
    value: {
      immediate: true,
      handler(value: any) {
        if (value) {
          this.getUnderWriters({
            query: {
              _id__in: Array.isArray(this.value) ? this.value : [this.value]
            }
          });
        }
      }
    }
  },
  methods: {
    ...userMapActions(["getUnderWriters"]),
    async remoteMethod(searchTerm: string) {
      try {
        await this.getUnderWriters({
          query: {
            __query: searchTerm
          }
        });
      } catch (error) {
        this.$appNotifyError("Error fetching underwriters");
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...userMapGetters(["getUnderWritersFromStore"]),
    ...userMapState(["makingApiRequest"]),
    underwriterListOptions(): any {
      return cloneDeep(this.getUnderWritersFromStore).map(
        (underwriter: any) => ({
          label: `${capitalizeFirstLetter(
            underwriter.firstName
          )} ${capitalizeFirstLetter(underwriter.lastName)}`,
          value: underwriter._id,
          key: underwriter._id
        })
      );
    }
  }
});
