var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col sm:flex-row border-b pb-4 mb-4 sm:gap-10"},[_c('div',{staticClass:"w-full sm:w-1/2"},[_c('div',{staticClass:"text-xl mb-8 flex items-center"},[_c('span',{staticClass:"select-none capitalize"},[_c('span',[_vm._v("Display Name: ")]),_vm._v(_vm._s(_vm.userInfo.displayName))]),(_vm.userInfo.status)?_c('status-tag',{attrs:{"status":_vm.userInfo.status}}):_vm._e()],1),_c('section',{staticClass:"flex gap-8 xs:gap-11 sm:gap-16 mb-4"},[_c('div',[_c('div',{staticClass:"font-medium capitalize"},[_vm._v("Name")]),_c('div',{staticClass:"uppercase"},[_vm._v(" "+_vm._s(_vm.userInfo.fullName)+" ")]),_c('div',{staticClass:"capitalize"},[_c('span',{staticClass:"px-2 py-1 uppercase bg-gray-200 text-xs font-semibold select-none"},[_vm._v(_vm._s(_vm.userInfo.role))])]),(
            !!_vm.userInfo &&
              !!_vm.userInfo.mailingAddress &&
              !!_vm.userInfo.mailingAddress._id
          )?_c('div',[_c('div',{staticClass:"font-medium mt-4"},[_vm._v("Mailing Address")]),_vm._v(" "+_vm._s(_vm.userInfo.mailingAddress.streetDirection || "")+" "+_vm._s(_vm.userInfo.mailingAddress.houseNumber)+" "+_vm._s(_vm.userInfo.mailingAddress.streetName)+" "+_vm._s(_vm.userInfo.mailingAddress.streetType)+" ")]):_vm._e(),(
            !!_vm.userInfo &&
              !!_vm.userInfo.mailingAddress &&
              !!_vm.userInfo.mailingAddress._id
          )?_c('div',[_vm._v(" "+_vm._s(_vm.userInfo.mailingAddress.city + ",")+" "+_vm._s(_vm.userInfo.mailingAddress.state)+" "+_vm._s(_vm.userInfo.mailingAddress.zipCode)+" ")]):_vm._e()]),_c('div',[(
            !!_vm.userInfo &&
              !!_vm.userInfo.physicalAddress &&
              !!_vm.userInfo.physicalAddress._id
          )?_c('div',[_c('div',{staticClass:"font-medium capitalize"},[_vm._v(" Physical Address ")]),(!!_vm.userInfo.physicalAddress._id)?_c('div',[_vm._v(" "+_vm._s(_vm.userInfo.physicalAddress.streetDirection || "")+" "+_vm._s(_vm.userInfo.physicalAddress.houseNumber)+" "+_vm._s(_vm.userInfo.physicalAddress.streetName)+" "+_vm._s(_vm.userInfo.physicalAddress.streetType)+" ")]):_vm._e(),(!!_vm.userInfo.physicalAddress._id)?_c('div',[_vm._v(" "+_vm._s(_vm.userInfo.physicalAddress.city + ",")+" "+_vm._s(_vm.userInfo.physicalAddress.state)+" "+_vm._s(_vm.userInfo.physicalAddress.zipCode)+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"font-medium capitalize"},[_vm._v("Email Address")]),_c('div',{staticClass:"uppercase"},[_vm._v(_vm._s(_vm.userInfo.email || "Unavailable"))])])]),_c('div',{staticClass:"w-full sm:w-1/2 mt-8 sm:mt-0"},[_vm._m(0),_c('table',{staticClass:"mb-6 pb-4",class:_vm.isAgent ||
        (!!_vm.userInfo && !!_vm.userInfo.phone && !!_vm.userInfo.phone.secondary)
          ? 'border-b'
          : '',staticStyle:{"width":"100%"}},[_c('tr',[(_vm.userInfo.adjusterCode || _vm.userInfo.agentCode)?_c('td',[_c('div',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.isAdjuster ? "Adjuster Code" : "Agency Code")+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.isAdjuster ? _vm.userInfo.adjusterCode : _vm.userInfo.agentCode)+" ")])]):_vm._e(),(!!_vm.userInfo && !!_vm.userInfo.phone && !!_vm.userInfo.phone.primary)?_c('td',[_c('div',{staticClass:"font-medium"},[_vm._v("Primary Phone Number")]),_c('div',[_vm._v(_vm._s(_vm.userInfo.phone.primary))])]):_vm._e()]),_c('br')]),(
        _vm.isAgent ||
          (!!_vm.userInfo && !!_vm.userInfo.phone && !!_vm.userInfo.phone.secondary)
      )?_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[(!!_vm.userInfo && !!_vm.userInfo.phone && !!_vm.userInfo.phone.secondary)?_c('td',[_c('div',{staticClass:"font-medium"},[_vm._v("Secondary Phone")]),_c('div',[_vm._v(_vm._s(_vm.userInfo.phone.secondary))])]):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"font-medium"},[_c('span',[_vm._v("Other Information")])])])
}]

export { render, staticRenderFns }