
import {
  propertyChangeRequestMapActions,
  propertyChangeRequestMapGetters,
  propertyChangeRequestMapState
} from "@/store/modules/propertychangerequest";
import { tableColumnsListPropertyChangeRequests } from "./TableColumns";
import PropertyChangeRequestTableActionsVue from "../../ChangeRequestTableActions/ChangeRequestTableActions.vue";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { get } from "lodash";
import ChangeRequestMixin from "@/components/ChangeRequests/ChangeRequestMixin";
import { clearSelectionsNotInData, getSystemFilesURL } from "@/helpers";
import Charts from "@/components/Charts/Charts.vue";
import VueWithMixins from "@/helpers/mixins";

export default VueWithMixins(ChangeRequestMixin).extend({
  name: "PropertyChangeRequestsListView",
  components: {
    Exporter: () => import("@/components/Exporter/Exporter.vue"),
    Charts,
    NoResultsFound
  },
  data() {
    return {
      tableColumns: tableColumnsListPropertyChangeRequests,
      pageKey: "Policy",
      exportModalKey: "exportPropertyChangeRequestsModal",
      deleteModalKey: "propertyChangeRequestDeleteModal",
      activityRoute: "/admin/propertyChangeRequest/activities",
      changeRequestRoute: "propertychangerequest",
      propertyChangeRequestNotFoundImage: ""
    };
  },
  async mounted() {
    try {
      const url = await getSystemFilesURL("property-change");
      if (url) {
        this.propertyChangeRequestNotFoundImage = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  },
  methods: {
    // get store methods
    ...propertyChangeRequestMapActions([
      "deletePropertyChangeRequest",
      "getPropertyChangeRequests",
      "updateRequestsStatus",
      "printPropertyChangeRequest",
      "deleteBulkChangeRequests",
      "restorePropertyChangeRequests"
    ]),
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Print Selected": {
          try {
            const documentIds: string[] = this.getSelectedDocumentIds;
            if (documentIds.length == 0) {
              let message = "No policy change requests selected";
              let messageType = "error";
              this.$appNotify({
                title: "Please note:",
                type: messageType,
                message: message,
                offset: 100,
                position: "top-right"
              });
            }

            await this.printCallback(
              documentIds,
              this.printPropertyChangeRequest,
              "property"
            );
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          } finally {
            this.loading = false;
            this.loadingText = "";
          }
          break;
        }
        case "Delete Selected": {
          try {
            this.loadingText = "Deleting selected change requests...";
            const ids = this.selectedDocuments.map((doc: any) => doc._id);
            await this.deleteBulkChangeRequests(ids);
            this.$appNotifySuccess("Selected Requests Deleted");
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
          } catch (error) {
            this.errorMessage = error.message;
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        case "Restore Selected": {
          try {
            this.loadingText = "Restoring selected change requests...";
            const ids = this.selectedDocuments.map((doc: any) => doc._id);
            await this.restorePropertyChangeRequests({ ids });
            this.$appNotifySuccess("Selected Requests Restored");
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
          } catch (error) {
            this.errorMessage = error.message;
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        default:
          break;
      }
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      data.isLegacy = this.queryOverride.isLegacy;
      const policyPrefix =
        data &&
        data.data &&
        data.data.policyInfo &&
        data.data.policyInfo.policyPrefix
          ? data.data.policyInfo.policyPrefix
          : undefined;

      switch (payload.name) {
        case "duplicate":
          //do nothing
          break;
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "view":
          this.viewChangeRequest(data);
          break;
        case "print":
          this.printCallback(
            data._id,
            this.printPropertyChangeRequest,
            "property",
            policyPrefix
          );
          break;
        case "edit":
          if (
            (data.status === "Not Submitted" ||
              (get(this.getCurrentUser, "role") === "admin" &&
                get(data, "status") !== "Received" &&
                !get(data, "printed"))) &&
            !data.isLegacy
          ) {
            this.$router
              .push(`/propertyChangeRequest/edit/${data._id}`)
              .catch(() => {});
            break;
          } else {
            this.viewChangeRequest(data);
            break;
          }
        case "restore":
          this.restoreChangeRequests(data._id);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async restoreChangeRequests(id: string): Promise<any> {
      try {
        this.loadingText = "Restoring policy change request...";
        this.loading = true;
        await this.restorePropertyChangeRequests({ ids: [id] });
        this.successMessage = "Claim Successfully Restored";
        this.fetchPageData({});
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    async deleteOnePayPlanChangeRequest(): Promise<void> {
      await this.deletePropertyChangeRequest(this.changeRequestId);
      this.$modal.hide("propertyChangeRequestDeleteModal");
      this.$appNotifySuccess("policy Change Request Deleted.");
    },
    getPropertyChangeRequestOperations() {
      return PropertyChangeRequestTableActionsVue;
    },
    async fetchPageData(queryObject: any): Promise<void> {
      const { __limit: limit, __sort: sort } = queryObject;
      if (!limit) {
        queryObject = {
          ...queryObject,
          __limit: 50
        };
      }
      const sortFormats: Record<string, string> = {
        "-submittedOnFormatted": "-submittedOn",
        submittedOnFormatted: "submittedOn",
        "-modifiedOnFormatted": "-modifiedOn",
        modifiedOnFormatted: "modifiedOn"
      };
      if (sort) {
        queryObject.__sort = sortFormats[sort];
      }
      try {
        this.loadingText = "Fetching Policy change requests...";
        this.loading = true;
        this.$emit("scrollTop");
        await this.getPropertyChangeRequests({
          query: { ...queryObject, ...this.queryOverride }
        });

        this.selectedDocuments = clearSelectionsNotInData(
          this.getPropertyChangeRequestsFromStore,
          this.selectedDocuments
        );
      } catch (error) {
        this.errorMessage =
          "Error fetching Policy change requests. Try again later";
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.reserveSelection = true;
      }
    },
    async customBulkActionHandler(action: string) {
      this.reserveSelection = false;
      switch (action) {
        case "Not Submitted":
        case "Submitted":
        case "Received":
        case "Issued": {
          try {
            await this.updateStatus(action, this.updateRequestsStatus);
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        default:
          break;
      }
    }
  },
  computed: {
    ...propertyChangeRequestMapGetters([
      "getPropertyChangeRequestsFromStore",
      "getPropertyChangeRequestsLinks",
      "getTotalRecords",
      "getMaxQuerySize"
    ]),

    ...propertyChangeRequestMapState(["makingApiRequest"]),
    tableActions(): string[] {
      return this.getCurrentUser && this.getCurrentUser.role === "admin"
        ? ["Export Policy Change Requests", "All Activities"].sort()
        : [];
    },
    chart(): string {
      return this.getChart("policy-change-status");
    }
  }
});
