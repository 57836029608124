var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"flex justify-between items-center mb-4 cursor-pointer",on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('div',{staticClass:"flex justify-baseline items-baseline"},[_c('p',{staticClass:"text-lg font-medium mr-2"},[_vm._v(" Add Endorsements ("+_vm._s(_vm.selectedOptionalEndorsements)+") ")]),_c('p',{staticClass:"text-lg font-medium block text-gray-500"},[(_vm.expanded)?_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.__getText("fields", "clickToClose")))]):_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.__getText("fields", "clickToExpand")))])])]),_c('div',[_c('font-awesome-icon',{staticClass:"text-blue-700 hover:text-blue-400 mr-2",attrs:{"icon":['fal', _vm.faIcon]}})],1)]),(_vm.expanded)?_c('div',_vm._l((_vm.options),function(option,key){return _c('SingleEndorsement',{key:key,attrs:{"option":option,"loading":_vm.loading,"disabled":_vm.disabled,"requiredEndorsementFormCodes":_vm.requiredEndorsementFormCodes},on:{"showModal":_vm.showModal,"inputValueChanged":_vm.inputValueChanged,"inputValueChangedCustom":_vm.inputValueChangedCustom,"endorsementHasErrors":_vm.handleValidation,"handleChangeCheckBox":_vm.handleCheckboxChange,"endorsementValuesHasErrors":_vm.handleEndorsementValuesHasErrors}})}),1):_vm._e(),_c('ModalMessage',{attrs:{"name":"endorsementsModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Endorsement","description":_vm.description,"showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.$modal.hide('endorsementsModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }