var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"errorMessage":_vm.error,"successMessage":_vm.message,"showSearch":false,"topActionButtonSecondary":_vm.secondaryToolbar,"topActionButtonPrimary":_vm.primaryToolbar},on:{"toolbarSelectItem":_vm.onToolbarItemSelected,"errorMessageClosed":function($event){_vm.error = ''}}},[_c('div',{staticClass:"mb-4 mt-2 flex items-center justify-end gap-5 top-0 right-10 py-2 px-4 font-semibold",staticStyle:{"color":"#034b9d"}},[_c('router-link',{attrs:{"to":"/billingchangerequests"}},[_c('span',[_vm._v("Looking for Billing/Cancellation Change Request? ")])]),(
        _vm.editing &&
          _vm.editing.data &&
          _vm.editing.data.insured &&
          _vm.editing.data.insured.lastName &&
          _vm.editing.data.insured.firstName
      )?_c('div',{staticClass:"cursor-pointer text-base text-atlas-blue hover:font-medium",on:{"click":_vm.editPolicyInsuredInfo}},[_c('div',[_c('i',{staticClass:"el-icon-edit font-extrabold"}),_vm._v("Edit")])]):_vm._e()],1),_c('custom-alert',{attrs:{"title":"Please Note","message":"Property must not be in foreclosure to use this form.","show":_vm.showForeclosureAlert},on:{"dismiss":function($event){_vm.showForeclosureAlert = false}}}),(_vm.editing?.data.policyInfo && !_vm.insuredInfo.changeRequestMade)?_c('PolicyInfo',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingText),expression:"loadingText"}],attrs:{"element-loading-text":_vm.loadingText,"policyInfo":_vm.editing?.data.policyInfo,"disabled":_vm.disablePolicyInfoComponent,"effectiveDateLabel":"Effective Date","isChangeRequest":true,"changeRequestType":"payPlanChangeRequest"},on:{"updateChangeRequestData":function($event){return _vm.handleChangeRequestCreation($event, 'data.policyInfo')},"paddedPolicyNumber":_vm.setPaddedPolicyNumber,"setLoadingText":function($event){_vm.loadingText = $event},"sendBackInsuredInfo":_vm.setInsuredInfo,"sendBackInsuredMailingAndLocAddress":_vm.setMailingAndLocAddresses}}):_vm._e(),(
      _vm.editing &&
        _vm.editing.data &&
        _vm.editing.data.insured &&
        _vm.editing.data.insured.lastName &&
        _vm.editing.data.insured.firstName
    )?_c('ChangeRequestInsured',{attrs:{"type":"new","changeRequestType":"PAY_PLAN_REQUEST","insuredData":_vm.editing.data.insured}}):_vm._e(),(
      _vm.editing &&
        _vm.editing.data.insured.lastName &&
        _vm.editing.data.insured.firstName
    )?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingText),expression:"loadingText"}],attrs:{"element-loading-text":_vm.loadingText}},[_c('div',{staticClass:"mb-10"},[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Pay Plan Change Request Actions ")])]),_c('el-collapse',{staticClass:"mb-10",model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[_c('el-tooltip',{attrs:{"content":"Property must not be in foreclosure to use this form.","placement":"top","effect":"light"}},[_c('el-collapse-item',{attrs:{"title":"Pay Plan Changes","name":"1"}},[_c('div',{staticClass:"pb-3"},[_c('label',{staticClass:"pr-4",attrs:{"for":""}},[_vm._v("Bill To: "),_c('span',[_c('em',[_vm._v("(must choose one) ")])])]),_c('el-radio-group',{on:{"handleChange":function($event){return _vm.handlePayPlanChanges(_vm.payPlanBillToKey, _vm.billToRadioButton)}},model:{value:(_vm.billToRadioButton),callback:function ($$v) {_vm.billToRadioButton=$$v},expression:"billToRadioButton"}},[_c('el-radio',{attrs:{"label":"insuredBilled"}},[_vm._v("Insured Billed")]),_c('el-radio',{attrs:{"label":_vm.payPlanMortgageValue}},[_vm._v("Mortgagee Billed (annual pay only)")])],1)],1),_c('div',{staticClass:"pb-3"},[_c('label',{staticClass:"pr-4",attrs:{"for":""}},[_vm._v("Payment Plan: "),_c('span',[_c('em',[_vm._v("(must choose one) ")])])]),_c('el-radio-group',{on:{"handleChange":function($event){return _vm.handlePayPlanChanges(
                  'data.payPlan.payPlanOptions',
                  _vm.paymentPlanRadioButton
                )}},model:{value:(_vm.paymentPlanRadioButton),callback:function ($$v) {_vm.paymentPlanRadioButton=$$v},expression:"paymentPlanRadioButton"}},[_c('el-radio',{attrs:{"label":"annual","checked":_vm.billToRadioButton === _vm.payPlanMortgageValue}},[_vm._v("Annual")]),(_vm.billToRadioButton !== _vm.payPlanMortgageValue)?_c('div',{staticStyle:{"display":"inline"}},_vm._l((_vm.payPlanBillingOptions),function(payPlan,index){return _c('el-radio',{key:index,attrs:{"label":payPlan.value}},[_vm._v(_vm._s(payPlan.label))])}),1):_vm._e()],1)],1)])],1),_c('el-collapse-item',{attrs:{"title":"Document Upload","name":"2"}},[_c('ChangeRequestFileUpload',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"formType":"propertyChangeRequest","attachments":_vm.editing.attachments},on:{"upload-success":_vm.attachmentUploadHandler,"delete-attachment":_vm.attachmentDeleteHandler}})],1)],1),(_vm.editing?.status && _vm.insuredInfo.changeRequestMade)?_c('span',{staticClass:"mb-20",staticStyle:{"float":"right"}},[_vm._v("Status : "+_vm._s(`${_vm.payPlanChangeRequestId ? "Saved" : _vm.editing?.status}`))]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }