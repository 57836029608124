
import { getDayRelativeInfo } from "@/helpers/dateProcessor";
import Vue from "vue";
export default Vue.extend({
  name: "ViewChangeRequestPolicyDetails",
  props: {
    changeRequestData: {
      type: Object,
      required: true
    }
  },
  methods: {
    makeHumanReadableDate(date: string) {
      return getDayRelativeInfo(date, "dateDate");
    }
  }
});
