var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.data)?_c('div',_vm._l((_vm.data.sections),function(section,index){return _c('div',{key:index},[(
        section.hasOwnProperty('showSection') ? section.showSection : true
      )?_c('dl',{staticClass:"pt-5",class:[section.showDivider ? 'border-t border-gray-200' : '']},[_c('div',{staticClass:"pb-3"},[_c('dt',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(section.sectionTitle)+" ")]),(section.hasOwnProperty('description'))?_c('p',[_vm._v(" "+_vm._s(section.description)+" ")]):_vm._e()]),_c('dl',{class:`border-gray-200 py-4 grid ${
            section?.sectionColumnSize
              ? `md:grid-cols-${section?.sectionColumnSize}`
              : ''
          } md:gap-y-0 gap-y-5`},_vm._l((section.information),function(info,index){return _c('div',{key:index,staticClass:"col-span-1"},[(info.hasOwnProperty('showColumn') ? info.showColumn : true)?_c('div',[_c('dt',{staticClass:"text-sm font-medium text-gray-500",class:[info.class ? info.class : '']},[_vm._v(" "+_vm._s(info.key)+" ")]),(!info.isArray)?_c('dd',{staticClass:"mt-1 text-sm text-gray-900",class:[info.class ? info.class : '']},[(info.value && !info?.isHTML)?_c('span',[_vm._v(_vm._s(info.value))]):_vm._e(),(info.value && info?.isHTML)?_c('span',{domProps:{"innerHTML":_vm._s(info.value)}}):_vm._e()]):_vm._e(),(info.isArray)?_c('dd',{staticClass:"mt-1 text-sm text-gray-900"},_vm._l((info.value),function(data,key){return _c('p',{key:key},[(data)?_c('span',[_vm._v(_vm._s(data))]):_vm._e()])}),0):_vm._e()]):_vm._e()])}),0)]):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }