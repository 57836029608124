
import Vue from "vue";

export default Vue.extend({
  name: "StatusTag",
  props: {
    status: {
      type: String,
      required: true
    }
  },

  computed: {
    computeStatusStyle() {
      if (this.status === "active") {
        return "bg-green-400";
      }
      if (this.status === "deleted") {
        return "bg-red-400";
      }
      return "bg-green-400";
    }
  }
});
