var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"title":"Documents","errorMessage":_vm.errorMessage,"topActionButtonPrimary":_vm.primaryButton,"topActionButtonSecondary":_vm.topButtonSecondaryItems},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"element-loading-text":_vm.loadingText,"data":_vm.atlasFileCategories,"paginationLinks":_vm.links,"totalRecords":_vm.getTotalRecords,"tableActions":_vm.tableActions,"tableColumns":_vm.tableColumns,"tableRowActions":_vm.tableRowActions},on:{"fetch-query":_vm.fetchCategories,"tableRowClicked":_vm.viewCallback,"table-row-action":_vm.handleResponseAction}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Documents Found."},slot:"notFound"})],1),_c('ModalMessage',{attrs:{"name":"atlasFileCategoryDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Document Category Deletion","description":`This will delete the category. Continue?`,"showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.deleteOneAtlasFileCategory()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('atlasFilesDeleteModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }