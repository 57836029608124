var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{staticClass:"relative",attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.message,"showSearch":false,"topActionButtonPrimary":{
    text: 'Save',
    key: 'save',
    disabled: Object.keys(_vm.updatedFields).length === 0 || _vm.inputsHasError,
    loading: _vm.makingApiRequest
  },"bottomActionButtonAfter":{
    text: 'Delete Communication',
    key: 'delete',
    class: 'text-red-500'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[(_vm.communicationSaved && _vm.communicationSaved.status === 'scheduled')?_c('SingleEditCountdown',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.didInit),expression:"!didInit"}],attrs:{"deliveryDate":_vm.communicationSaved && _vm.communicationSaved.deliveryDate},on:{"editSavedCommunication":_vm.editScheduledCommunication}}):_vm._e(),_c('SingleCommunicationFields',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"isNew":false,"isDisabled":_vm.communicationSaved && _vm.communicationSaved.status === 'scheduled',"communicationId":_vm.communicationId},on:{"save":_vm.autoSave,"communicationInputHasError":_vm.handleCommunicationInputError}}),_c('saveChanges',{on:{"clickedSave":_vm.beforeRouteLeaveSave,"clickedDiscard":_vm.beforeRouteLeaveDiscardChanges}}),_c('ModalMessage',{attrs:{"name":"communicationsSingleDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Communication Deletion","description":"Are you sure you want to delete this communication?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.removeCommunication()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('communicationsSingleDeleteModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }