
import { getUserRoleOptions, isExemptedUserRole } from "@/helpers";
import Vue from "vue";

export default Vue.extend({
  props: {
    agentCode: {
      type: String,
      required: false
    }
  },
  components: {
    UserListPickerModal: () =>
      import("@/components/User/ListUsers/UserListPickerModal.vue")
  },
  name: "agent-picker-modal",
  computed: {
    queryOverride() {
      const { role = "", agentCode = "" } = this.$getCurrentUser || {};

      if (role) {
        const roleValues = getUserRoleOptions(role).map(role => role.value);

        const defaultQuery: Record<string, any> = {
          role__in: roleValues.join(","),
          status: "active",
          deleted: false
        };
        if (!isExemptedUserRole(role)) {
          defaultQuery.agentCode = this.agentCode || agentCode;
        }
        return defaultQuery;
      }
      return null;
    }
  }
});
