var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":true,"topActionButtonPrimary":{
    text: 'Create New Company',
    key: 'createNewCompany'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading || _vm.loadingText),expression:"loading || loadingText"}],attrs:{"hideAllFilters":false,"tableRowActions":_vm.tableRowActions,"element-loading-text":_vm.loadingText,"data":_vm.companies,"tableActions":_vm.tableActions,"addAdvancedFilter":false,"tableColumns":_vm.tableColumns,"hideToggleIcons":true,"totalRecords":_vm.getTotalRecords,"paginationLinks":_vm.getCompaniesLinks},on:{"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewCallback,"table-row-action":_vm.handleResponseAction}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Companies Found."},slot:"notFound"})],1),_c('ModalMessage',{attrs:{"name":"companiesDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Company Deletion","description":"This will delete the company. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.deleteOneCompany()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('companiesDeleteModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }