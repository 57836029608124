import store from "@/store";
import axios from "axios";
import { getOrigin, getPathAPI } from ".";

export function makeBlobUrlFromBase64(base64String: string): string {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
}

export async function downloadLossFormDocument(
  this: Vue,
  data: Record<string, any>
): Promise<void> {
  const newTab = window.open(
    `${getOrigin()}/loading-preview/noMessage`,
    "_blank"
  );
  store.commit("claims/SET_STATES", { makingApiRequest: true });
  axios({
    url: `${getPathAPI()}/storage-forms/print-claims-lookup-by-claim-number-form?accessToken=${localStorage.getItem(
      "access"
    )}`,
    method: "POST",
    data
  })
    .then((response: any) => {
      const url = makeBlobUrlFromBase64(response.data.response.document);
      if (newTab) {
        newTab.location.href = url;
      }
    })
    .catch((reason: any) => {
      newTab?.close();
      this.$appNotifyError(reason.message);
    })
    .finally(() => {
      store.commit("claims/SET_STATES", { makingApiRequest: false });
    });
}

export async function downloadClaimsDocumentPolicyNumber(
  data: Record<string, any>
): Promise<void> {
  const newTab = window.open(
    `${getOrigin()}/loading-preview/noMessage`,
    "_blank"
  );
  store.commit("claims/SET_STATES", { makingApiRequest: true });
  axios({
    url: `${getPathAPI()}/storage-forms/print-claims-lookup-by-policy-number-form?accessToken=${localStorage.getItem(
      "access"
    )}`,
    method: "POST",
    data
  })
    .then((response: any) => {
      const url = makeBlobUrlFromBase64(response.data.response.document);
      if (newTab) {
        newTab.location.href = url;
      }
      // const link = document.createElement("a");
      // link.href = url;
      // link.target = "_blank";
      // document.body.appendChild(link);
      // link.click();
    })
    .catch(() => {
      newTab?.close();
    })
    .finally(() => {
      store.commit("claims/SET_STATES", { makingApiRequest: false });
    });
}
interface GenericError extends Error {
  response: Record<string, any>;
}
const extractErrorMessage = (error: GenericError) => {
  return error?.response?.data?.response?.message || error?.message;
};
export async function downloadClaimsDocument(
  data: Record<string, any>
): Promise<void> {
  const newTab = window.open(
    `${getOrigin()}/loading-preview/noMessage`,
    "_blank"
  );
  store.commit("claims/SET_STATES", { makingApiRequest: true });

  try {
    const response = await axios({
      url: `${getPathAPI()}/storage-forms/print-claims-storage-form?accessToken=${localStorage.getItem(
        "access"
      )}`,
      method: "POST",
      data
    });

    const url = makeBlobUrlFromBase64(response.data.response.document);
    if (newTab) {
      newTab.location.href = url;
    }
  } catch (error) {
    const message = extractErrorMessage(error);
    newTab?.close();
    throw new Error(message);
  } finally {
    store.commit("claims/SET_STATES", { makingApiRequest: false });
  }
}

export async function downloadQuoteDocument(
  data: Record<string, any>,
  loadingString: any = "noMessage"
): Promise<any> {
  const newTab = window.open(
    `${getOrigin()}/loading-preview/${loadingString}`,
    "_blank"
  );
  store.commit("quote/SET_STATES", { makingApiRequest: true });
  await axios({
    url: `${getPathAPI()}/quotes/print?accessToken=${localStorage.getItem(
      "access"
    )}`,
    method: "POST",
    data
  })
    .then((response: any) => {
      const url = makeBlobUrlFromBase64(response.data.response.document);
      if (newTab) {
        newTab.location.href = url;
      }
    })
    .catch(e => {
      const message = extractErrorMessage(e);
      newTab?.close();
      throw new Error(message);
    })
    .finally(() => {
      store.commit("quote/SET_STATES", { makingApiRequest: false });
    });
}

export async function downloadPersonalLiabilityDocument(
  data: Record<string, any>
): Promise<void> {
  const newTab = window.open(
    `${getOrigin()}/loading-preview/noMessage`,
    "_blank"
  );
  store.commit("personalLiabilityPolicy/SET_STATES", {
    makingApiRequest: true
  });
  await axios({
    url: `${getPathAPI()}/personal-liability-policies/print?accessToken=${localStorage.getItem(
      "access"
    )}`,
    method: "POST",
    data
  })
    .then((response: any) => {
      const url = makeBlobUrlFromBase64(response.data.response.document);
      if (newTab) {
        newTab.location.href = url;
      }
    })
    .catch(error => {
      const errorMessage = extractErrorMessage(error);
      newTab?.close();
      throw new Error(errorMessage);
    })
    .finally(() => {
      store.commit("personalLiabilityPolicy/SET_STATES", {
        makingApiRequest: false
      });
    });
}

export async function printSingleChangeRequest(
  this: Vue,
  printData: Record<string, any>
) {
  const newTab = window.open(
    `${getOrigin()}/loading-preview/noMessage`,
    "_blank"
  );
  try {
    const data = {
      _ids: Array.isArray(printData.changeRequestIds)
        ? printData.changeRequestIds
        : [printData.changeRequestIds],
      site: "ATLAS",
      policyPrefix: printData.policyPrefix
    };
    const fileURL = await store.dispatch(`${printData.printAction}`, data);

    await store.dispatch("");
    if (fileURL) {
      const url = makeBlobUrlFromBase64(fileURL.document);
      if (newTab) {
        newTab.location.href = url;
      }
      this.$appNotifySuccess("Printing change request");
    } else {
      newTab?.close();
      this.$appNotifyError("Error printing request. Try again later.");
    }
  } catch (error) {
    newTab?.close();
    this.$appNotifyError(error.message);
  }
}
