var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonPrimary":{
    text: 'Save PPC',
    key: 'create',
    disabled: _vm.disableButton,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancelAddingPPC'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[(!_vm.resourceNotFound)?[_c('form-builder',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],ref:"formElement",attrs:{"element-loading-text":_vm.loadingText,"schemas":_vm.schema,"formValues":_vm.editing},on:{"formFieldChanged":_vm.formFieldChangedHandler,"validationChanged":function($event){_vm.validationData = $event}}}),_vm._l((_vm.companyTerritories),function(territory,index){return _c('FormBuilderDataCollector',{key:index,attrs:{"editing":territory,"emitOnChange":true,"allowDelete":true,"dynamicOptions":{ 'companyTerritories.companyNumber': _vm.companyNumbers },"valueMainKey":"companyTerritories","schemas":_vm.gbsTerritoryInfoSchema,"title":"Territory Data"},on:{"edit":function($event){return _vm.editTerritoryInfo($event, index)},"delete":function($event){return _vm.deleteTerritory(index)},"formValidationChanged":_vm.handleTerritoryValidationChanged}})}),_c('IAButton',{staticClass:"block",attrs:{"title":"Add Territory","click":() => _vm.addTerritoryInfo(),"type":"text"}}),_c('ModalMessage',{attrs:{"name":"gbshelpSingleEditModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":_vm.__getText('global', 'willSaveChangesTitle'),"description":_vm.__getText('global', 'willSaveChanges'),"showClose":true,"buttons":[
        {
          text: `Ok`,
          classList: ['bg-gray-100 text-gray-600 border-gray-300'],
          click: () => _vm.submit()
        },
        {
          text: `Cancel`,
          classList: ['bg-red-700'],
          click: () => _vm.$modal.hide('gbshelpSingleEditModal')
        }
      ]}})]:_c('ResourceNotFound',{attrs:{"title":"Resource not found.","subtitle":"Sorry, the PPC data you were looking for could not be found.","actionButtonTitle":"Back to PPC List","actionRedirectPath":"/admin/ppc"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }