
import SingleQuickLink from "@/components/QuickLinks/SingleQuickLink.vue";
import Vue from "vue";

import { getSystemFilesURL, ImageName } from "@/helpers";

export default Vue.extend({
  components: { SingleQuickLink },
  name: "Blocking",
  data() {
    return {
      quoteValidationImage: "",
      prohibitBindingImage: "",
      emergencyMaintenanceImage: ""
    };
  },
  async mounted() {
    try {
      const fileTypes: { [key: string]: ImageName } = {
        quoteValidationImage: "quote-validation",
        prohibitBindingImage: "prohibit-binding",
        emergencyMaintenanceImage: "emergency-maintenance"
      };
      const vm = this as Record<string, any>;
      for (const key in fileTypes) {
        const url = await getSystemFilesURL(fileTypes[key]);
        if (url) {
          vm[key] = url;
        }
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  }
});
