<template>
  <div>
    <dl
      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 border-gray-200 py-4"
      :class="{ 'border-t': !hideTopBorder }"
    >
      <div class="sm:col-span-4">
        <dt class="font-medium text-2xl">
          {{ label }}
        </dt>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">
          Mortgagee Name
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ value.mortgageName }} ({{ value.shortName }})
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">
          City
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ value.city || "" }}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">
          State
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ value.state || "" }}
        </dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">
          Zip Code
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ value.zipCode || "" }}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">
          Address
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ value.address || "" }}
        </dd>
      </div>
    </dl>
    <div class="flex space-x-12 justify-between mt-4 mb-8">
      <TextField
        :fixedLabels="true"
        ref="field_loanNumber"
        label="Loan Number"
        placeholder="Loan Number"
        :required="false"
        :value="value.loanNumber"
        :disabled="disabled"
        @blur="$emit('edit-loan-number', $event)"
      ></TextField>

      <div>
        <IAButton
          title="Delete "
          faIcon="trash-alt"
          :click="() => $emit('delete')"
          type="text"
          class="text-red-500"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "MortgageEditor",
  props: {
    hideTopBorder: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },
    label: {
      type: String,
      required: false,
      default: "Mortgagee"
    }
  },
  data() {
    return {};
  }
});
</script>

<style></style>
