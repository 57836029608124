
import Vue from "vue";
import GoogleAutoComplete from "@/components/User/GoogleAutoComplete.vue";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import { getSystemFilesURL } from "@/helpers";

export default Vue.extend({
  name: "google-auto-complete-modal",
  components: {
    GoogleAutoComplete,
    CustomAlert
  },
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: ""
    },
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: String,
      required: false,
      default: ""
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: "Search Address"
    },
    modalName: {
      type: String,
      required: true,
      default: "googleAutoCompleteModal"
    }
  },
  data() {
    return {
      mapImage: ""
    };
  },
  async mounted() {
    try {
      const url = await getSystemFilesURL("map-image");
      if (url) {
        this.mapImage = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  }
});
