var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false},on:{"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[(!_vm.resourceNotFound)?_c('div',[_c('div',{staticClass:"ml-8 flex justify-between",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"flex w-full flex-row-reverse",staticStyle:{"float":"right"}},[_c('el-tag',{attrs:{"type":_vm.editing && _vm.editing.status === 'approved' ? 'success' : 'info',"size":"large"}},[_vm._v(" "+_vm._s(_vm.editing ? _vm.camelCaseToTitleCase(_vm.editing.status) : "")+" ")])],1)]),_c('div',{staticClass:"mt-6"}),_c('div',{staticClass:"flex flex-col sm:flex-row border-b mt-6 pb-4 mb-4"},[_c('div',{staticClass:"w-full sm:w-1/2"},[(_vm.agency && _vm.agency.agencyName)?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v("Agency Name and Code")]),_c('div',[_vm._v(" "+_vm._s(_vm.agency.agencyName)+" - "+_vm._s(_vm.agency.agencyCode)+" ")])]):_vm._e(),(_vm.agency && _vm.agency.subAgency)?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"font-medium"},[_vm._v("Sub Agency Of")]),_c('div',[_vm._v(" "+_vm._s(_vm.agency.subAgency.agencyName)+" - "+_vm._s(_vm.agency.subAgency.agencyCode)+" ")])]):_vm._e(),(_vm.agency?.companies)?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"font-medium"},[_vm._v("Companies")]),_vm._l((_vm.agency?.companies),function(company){return _c('div',{key:company._id},[_vm._v(" "+_vm._s(company.companyName)+" ")])})],2):_vm._e()]),_c('div',{staticClass:"w-full sm:w-1/2 mt-8 sm:mt-0"},[_c('div',{staticClass:"flex justify-between pb-4"},[(
              _vm.agency?.physicalAddress &&
                (_vm.agency.physicalAddress.houseNumber ||
                  _vm.agency.physicalAddress.houseNumber ||
                  _vm.agency.physicalAddress.city ||
                  _vm.agency.physicalAddress.zipCode)
            )?_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Physical Address")]),_c('div',[_vm._v(" "+_vm._s(_vm.agency.physicalAddress.houseNumber)+" "+_vm._s(_vm.agency.physicalAddress.streetName)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.agency.physicalAddress.city)+" "+_vm._s(_vm.agency.physicalAddress.state)+" "+_vm._s(_vm.agency.physicalAddress.zipCode)+" ")])]):_vm._e(),(
              _vm.agency?.mailingAddress &&
                (_vm.agency.mailingAddress.houseNumber ||
                  _vm.agency.mailingAddress.houseNumber ||
                  _vm.agency.mailingAddress.city ||
                  _vm.agency.mailingAddress.zipCode)
            )?_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Mailing Address")]),_c('div',[_vm._v(" "+_vm._s(_vm.agency.mailingAddress.houseNumber)+" "+_vm._s(_vm.agency.mailingAddress.streetName)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.agency.mailingAddress.city)+" "+_vm._s(_vm.agency.mailingAddress.state)+" "+_vm._s(_vm.agency.mailingAddress.zipCode)+" ")])]):_vm._e()]),(_vm.agency?.underWriterData)?_c('div',{staticClass:"border-t pt-4"},[_c('div',{staticClass:"font-medium"},[_vm._v("Underwriter")]),_c('div',{staticClass:"flex justify-between mt-3"},[_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Full name")]),_c('div',[_vm._v(" "+_vm._s(_vm.agency.underWriterData.firstName)+" "+_vm._s(_vm.agency.underWriterData.lastName)+" ")])]),_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Email")]),_c('div',[_vm._v(_vm._s(_vm.agency.underWriterData.email))])])])]):_vm._e()])]),_c('p',{staticClass:"mb-4 text-2xl"},[_vm._v("Agency Members")]),(!_vm.hideUserListView)?_c('UsersListView',{ref:"usersListView",attrs:{"isPage":false,"hideCreateButton":true,"hideSearchBar":true,"queryOverride":{ agencyId: _vm.agencyId },"hideToolBar":true}}):_vm._e()],1):_c('ResourceNotFound',{attrs:{"title":_vm.resourceNotFoundMsg,"actionButtonTitle":"Back to Agencies","actionRedirectPath":"/agencies"}}),_c('div',{staticClass:"flex gap-2 w-full mt-8"},[_c('UserListPickerModal',{attrs:{"additionalBulkTableActions":_vm.bulkAction,"emitOnAction":_vm.emitOnAction,"queryOverride":{ agencyId__ne: _vm.agencyId, role: 'agent' },"topButtons":_vm.topButtonForUserList},on:{"closed":_vm.closedAddUserModal,"tableRowClicked":_vm.promptAddUserToAgency,"bulkAction":_vm.promptAddUserToAgency,"topButtonClicked":_vm.userListToButtonClicked}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }