import { truncateString } from "@/helpers";
import { doFormatDateMMDDYYYY } from "@/store/modules/propertychangerequest/utils";
import { get } from "lodash";
function formatRecipients(recipients: string[]): string {
  if (recipients && recipients.length > 0) {
    const fullNames = recipients
      .map((recipient: any) => {
        const firstName = get(recipient, "firstName", "N/A");
        const lastName = get(recipient, "lastName", "N/A");
        return `${firstName} ${lastName}`;
      })
      .join(", ");
    if (recipients.length >= 5) {
      return `${fullNames}...`;
    }
    return fullNames;
  }
  return "N/A";
}

export const tableColumnsListCommunications = {
  createdOn: {
    formatDataKey: "Created",
    formatDataValue: (arg: string) => doFormatDateMMDDYYYY(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0,
    colWidth: 120
  },
  title: {
    formatDataKey: "Title",
    formatDataValue: (arg: string) => truncateString(arg, 55),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },
  recipientsData: {
    formatDataKey: "Recipients",
    formatDataValue: (arg: any) => {
      return formatRecipients(arg);
    },
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    sortMethod: (a: any, b: any) => {
      return formatRecipients(a.recipientsData).localeCompare(
        formatRecipients(b.recipientsData),
        undefined,
        {
          sensitivity: "base"
        }
      );
    }
  },
  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    colWidth: 125
  },
  priority: {
    formatDataKey: "Priority",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4,
    colWidth: 125
  },
  expirationDate: {
    formatDataKey: "Expires On",
    formatDataValue: (arg: string) =>
      isNaN(new Date(arg).getDate()) ? "" : doFormatDateMMDDYYYY(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 5,
    colWidth: 130
  }
};
