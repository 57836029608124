var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"flex flex-row w-full"},[(
        _vm.$getCurrentUser &&
          _vm.$getCurrentUser.role &&
          _vm.$getCurrentUser.role === 'admin'
      )?_c('el-input',{staticClass:"w-1/4",attrs:{"placeholder":"Search","clearable":true,"size":"medium","suffix-icon":"el-icon-search"},on:{"input":_vm.doAgentReportSearch,"change":_vm.searchInputChange,"clear":_vm.clearSearchQuery},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}):_vm._e(),_c('el-date-picker',{staticClass:"ml-2 w-1/5",attrs:{"type":"year"},on:{"change":_vm.yearChanged},model:{value:(_vm.yearPicked),callback:function ($$v) {_vm.yearPicked=$$v},expression:"yearPicked"}})],1),_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],ref:"reportListView",class:_vm.filterClass,attrs:{"hideAllFilters":false,"element-loading-text":_vm.loadingText,"data":_vm.agentreports,"tableActions":_vm.tableActions,"addAdvancedFilter":true,"hideSearchBar":_vm.windowSize === 'sm' ? false : true,"tableColumns":_vm.tableColumns,"hideToggleIcons":true,"totalRecords":_vm.getTotalRecords,"paginationLinks":_vm.getAgentReportLinks ? _vm.getAgentReportLinks : {},"filters":_vm.filters},on:{"table-row-action":_vm.handleResponseAction,"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewCallback,"table-action":_vm.tableActionHandler}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Agent Reports Found."},slot:"notFound"})],1),_c('ModalMessage',{attrs:{"name":"agenciesReportsListModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Agent Report Deletion","description":"This will delete the Agent Reports. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.deleteOneAgentReports()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('agenciesReportsListModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }