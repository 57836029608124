
import { christmasify, logoPath } from "@/helpers/generalHelpers";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      logoPath: ""
    };
  },
  async mounted() {
    try {
      const url = await logoPath();
      if (url) {
        this.logoPath = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  },
  methods: {
    doChristmasify(): boolean {
      return christmasify();
    }
  }
});
