const CACHE_NAME = "atlas-image-cache";
import Bugsnag from "@bugsnag/js";
/**
 * Fetches and caches an image by URL. Returns the cached response if available.
 *
 * @param url - The URL of the image to fetch and cache.
 * @returns The response object from the cache or network.
 * @throws Error if the network response is not OK.
 */
async function cacheImage(url: string): Promise<Response | undefined> {
  try {
    const cache = await caches.open(CACHE_NAME);
    const cachedResponse = await cache.match(url);
    if (cachedResponse) {
      return cachedResponse;
    }

    const fetchResponse = await fetch(url);

    if (!fetchResponse.ok) {
      throw new Error(`Failed to fetch ${url}: ${fetchResponse.statusText}`);
    }

    await cache.put(url, fetchResponse.clone());
    return fetchResponse;
  } catch (error) {
    Bugsnag.notify(error);
  }
}
export async function loadImage(url: string) {
  try {
    const response = await cacheImage(url);
    if (response) {
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    }
  } catch (error) {
    Bugsnag.notify(error);
  }
}
