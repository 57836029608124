var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"mt-8 sm:mx-auto sm:w-full sm:max-w-4xl"},[_c('div',{staticClass:"bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"},[_c('div',{staticClass:"sm:mx-auto sm:w-full sm:max-w-md mb-10"},[_c('router-link',{attrs:{"to":"/login"}},[_c('img',{staticClass:"mx-auto h-12 w-auto",attrs:{"src":_vm.logoPath,"alt":"Workflow"}})]),_c('h2',{staticClass:"mt-6 text-center text-3xl font-extrabold text-gray-900"},[_vm._v(" "+_vm._s(_vm.isAdjuster ? _vm.__getText("registration", "adjusterHeader") : _vm.__getText("registration", "registerHeader"))+" ")])],1),_c('div',[_c('el-form',{staticClass:"form-signup",on:{"submit":_vm.signUp}},[_c('el-row',[_c('input',{staticStyle:{"position":"fixed","top":"-100px","left":"-100px","width":"5px","opacity":"0"},attrs:{"type":"password","name":"fakePasswordRemembered"}}),_c('text-field',{ref:"field_firstName",attrs:{"label":_vm.__getText('fields', 'firstNameLabel'),"value":_vm.userInfo.firstName,"placeholder":_vm.__getText('fields', 'firstNameLabel'),"required":true,"type":'text',"fixedLabels":true,"validations":[
                {
                  method: 'minLength',
                  value: 2,
                  error: _vm.__getText('fields', 'nameFieldValidateError')
                }
              ]},on:{"input":function($event){_vm.userInfo.firstName = $event}}}),_c('text-field',{ref:"field_lastName",attrs:{"label":_vm.__getText('fields', 'lastNameLabel'),"value":_vm.userInfo.lastName,"placeholder":_vm.__getText('fields', 'lastNameLabel'),"required":true,"type":'text',"fixedLabels":true,"validations":[
                {
                  method: 'minLength',
                  value: 2,
                  error: _vm.__getText('fields', 'nameFieldValidateError')
                }
              ]},on:{"input":function($event){_vm.userInfo.lastName = $event}}}),_c('text-field',{ref:"field_phone",attrs:{"label":_vm.__getText('fields', 'primaryPhoneNumberLabel'),"placeholder":_vm.__getText('fields', 'primaryPhoneNumberLabel'),"type":'phone',"value":_vm.userInfo.phone.primary,"required":true,"fixedLabels":true,"validations":[
                {
                  method: 'minLength',
                  value: 12,
                  error: 'Invalid phone number'
                }
              ]},on:{"input":function($event){_vm.userInfo.phone.primary = $event}}}),_c('text-field',{ref:"field_email",attrs:{"label":_vm.__getText('fields', 'emailFieldLabel'),"value":_vm.userInfo.email,"loading":_vm.checkingEmail,"placeholder":_vm.__getText('fields', 'emailFieldLabel'),"required":true,"type":'email',"fixedLabels":true,"customValidator":_vm.customEmailValidator,"validations":[
                {
                  method: 'email',
                  value: true,
                  error: _vm.__getText('fields', 'emailFieldValidateError')
                },
                { method: 'custom', error: 'Email already in use' }
              ]},on:{"input":function($event){_vm.userInfo.email = $event},"change":_vm.checkEmailAlreadyInUse}}),(!_vm.isAdjuster)?_c('text-field',{ref:"field_agencyCode",attrs:{"label":"Agency Code","type":'text',"value":_vm.userInfo.agencyCode,"required":!_vm.isAdjuster,"loading":_vm.makingApiRequest,"fixedLabels":true,"customValidator":_vm.customAgencyValidator,"validations":[
                {
                  method: 'maxLength',
                  value: 15,
                  error: 'Agency Code Must Not Be More Than 15'
                },
                {
                  method: 'custom',
                  error: 'No agency with that agencyCode exists'
                }
              ]},on:{"input":function($event){_vm.userInfo.agencyCode = $event},"change":_vm.checkAgencyCodeValidity}}):_vm._e(),(_vm.isAdjuster)?_c('text-field',{ref:"field_adjusterCode",attrs:{"label":"Adjuster Code","type":'text',"value":_vm.userInfo.adjusterCode,"required":_vm.isAdjuster,"loading":_vm.makingApiRequest,"fixedLabels":true,"validations":[
                {
                  method: 'maxLength',
                  value: 5,
                  error: 'Adjuster Code Must Not Be More Than 5'
                },
                {
                  method: 'minLength',
                  value: 3,
                  error: 'Adjuster Code Must Not Be Less Than 3'
                }
              ]},on:{"input":function($event){_vm.userInfo.adjusterCode = $event.toUpperCase()},"blur":_vm.setAdjusterFirm}}):_vm._e(),(_vm.isAdjuster)?_c('div',{staticClass:"-mt-5"},[_c('label',{staticClass:"text-gray-600 font-medium",on:{"click":function($event){_vm.adjusterFirmFocused = true}}},[_vm._v(" Adjusting Firm ")]),_c('el-autocomplete',{ref:"field_adjustingFirmTxt",staticClass:"w-full mb-10",attrs:{"value":_vm.userInfo.adjustingFirm,"fetch-suggestions":_vm.filterAdjusterFirms,"placeholder":_vm.adjusterFirmFocused ? 'Enter Adjusting firm' : '',"trigger-on-focus":false,"loading":_vm.makingApiRequest,"required":_vm.isAdjuster,"value-key":"label","suffix-icon":"true"},on:{"input":function($event){_vm.userInfo.adjustingFirm = $event},"select":_vm.handleSelectAdjustingFirm,"focus":function($event){_vm.adjusterFirmFocused = true},"blur":function($event){_vm.adjusterFirmFocused = false}}},[_c('span',{staticClass:"mr-2 text-xs",class:_vm.adjustingFirmValid ? 'text-green-400' : 'text-red-500',attrs:{"slot":"suffix","tabindex":9999},slot:"suffix"},[_c('font-awesome-icon',{attrs:{"icon":[
                      'fas',
                      `${_vm.adjustingFirmValid ? 'check' : 'asterisk'}`
                    ]}})],1)])],1):_vm._e(),(!_vm.isAdjuster)?_c('form-builder',{ref:"licenseFormElement",attrs:{"schemas":_vm.getLicenseFormBuilderSchema,"formValues":{}},on:{"formFieldChanged":_vm.formFieldChangeHandler,"validationChanged":() => {}}}):_vm._e(),_c('PasswordField',{attrs:{"loading":false,"isAdmin":false,"isNew":true,"disabled":(_vm.isAdjuster && !_vm.userInfo.adjusterCode) ||
                  (!_vm.isAdjuster && !_vm.userInfo.agencyCode),"passwordMessage":'Enter valid password',"fixedLabels":true},on:{"input":_vm.passwordChangeHandler}})],1),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('IAButton',{staticClass:"w-full p-4 mt-4",attrs:{"id":"sign-btn","click":_vm.signUp,"title":_vm.__getText('registration', 'registerButtonText'),"disabled":!_vm.hasValidRegistrationData}})],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }