
import { formatCurrencyCompactLong } from "@/plugins/filters";

export default {
  name: "Taxes",
  props: {
    rate: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatCurrency(value: number): string {
      if ((value && !isNaN(value)) || value === 0) {
        return formatCurrencyCompactLong(value);
      } else {
        return "N/A";
      }
    }
  }
};
