
import { agencyMapState } from "@/store/modules/agency";
import Vue from "vue";
export default Vue.extend({
  name: "custom-disabled-text-field-with-input",
  props: {
    type: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Select"
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: ""
    };
  },
  computed: {
    ...agencyMapState(["editing", "agencies"]),
    prefix(): string {
      if (this.editing?.subAgencyOf) {
        const agency = this.agencies.find(
          (agency: any) => agency._id === this.editing.subAgencyOf
        );
        return agency ? agency.agencyCode : "";
      }
      return "";
    }
  }
});
