var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ModalMessage',{attrs:{"name":"discard-changes-modal","icon":['fal', 'exclamation-circle'],"iconClass":"text-yellow-600 text-6xl","title":_vm.title ? _vm.title : _vm.__getText('global', 'unsavedChangesWarningText'),"buttons":[
      {
        text: _vm.primaryButtonText
          ? _vm.primaryButtonText
          : _vm.__getTextUcFirst('simple', 'save'),
        classList: ['bg-gray-100 text-gray-600 border-gray-300'],
        click: () => _vm.$emit('clickedSave')
      },
      {
        text: _vm.__getTextStartCase('simple', 'discard'),
        classList: ['bg-red-600'],
        click: () => _vm.$emit('clickedDiscard')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }