
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import PolicyInquiryActivitiesTimelineItem from "./PolicyInquiryActivitiesTimelineItem.vue";
interface PolicyInquiryActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "policyInquiryActivitiesView",
  components: { PolicyInquiryActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): PolicyInquiryActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
        MAKE_POLICY_INQUIRY_SUCCESS,
        MAKE_POLICY_INQUIRY_FAILURE
          `.replace(/\s/g, ""),
        activityType: "PolicyInquiryActivity"
      };
    }
  }
});
