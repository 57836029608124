
import Vue from "vue";
import DisplayData from "@/components/DataView/DisplayData.vue";

import {
  userMapActions,
  userMapMutations,
  userMapState
} from "@/store/modules/user";
import { get, startCase } from "lodash";
import { formatDate } from "@/helpers/dateProcessor";
export default Vue.extend({
  name: "ViewSingleUser",
  async mounted() {
    this.$nextTick(async function() {
      try {
        const response = await this.getUserProfile(this.userId);
        this.SET_EDIT_USER(response);
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      }
    });
  },
  components: {
    DisplayData
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  methods: {
    ...userMapActions(["getUserProfile"]),
    ...userMapMutations(["SET_EDIT_USER"])
  },
  computed: {
    ...userMapState(["makingApiRequest", "editing"]),
    firstName(): string {
      return get(this.editing, "firstName", "");
    },
    lastName(): string {
      return get(this.editing, "lastName", "");
    },
    fullName(): string {
      return get(this.editing, "fullName", "");
    },
    userEmail(): string {
      return get(this.editing, "email", "");
    },
    phone() {
      return get(this.editing, "phone");
    },
    secondaryPhone(): string {
      return this.phone ? get(this.phone, "secondary", "N/A") : "N/A";
    },
    primaryPhone(): string {
      return this.phone ? get(this.phone, "primary", "N/A") : "N/A";
    },
    profileRole(): string {
      return startCase(get(this.editing, "role", ""));
    },
    agentLicense(): string {
      return get(this.editing, "licenseNumber", "");
    },
    sectionData(): Record<string, any> {
      const personalInformationSection = [
        {
          sectionTitle: "Personal Information",
          sectionColumnSize: 4,
          showSection: true,
          showDivider: false,
          information: [
            {
              key: "First Name",
              value: this.firstName
            },
            {
              key: "Last Name",
              value: this.lastName
            },
            {
              key: "Full Name",
              value: this.fullName
            },
            {
              key: "Email",
              value: `<a href='mailto:${this.userEmail}' class='text-atlas-blue'>${this.userEmail}</a>`,
              isHTML: true
            }
          ]
        },
        {
          sectionTitle: "",
          showSection: true,
          showDivider: false,
          sectionColumnSize: 4,
          information: [
            {
              key: "Primary Phone Number",
              value: `<a href='tel:${this.primaryPhone}' class='text-atlas-blue'>${this.primaryPhone}</a>`,
              isHTML: true
            },

            {
              key: "Secondary Number",
              value: `<a href='tel:${this.secondaryPhone}' class='text-atlas-blue'>${this.secondaryPhone}</a>`,
              isHTML: true
            },
            {
              key: "Role",
              value: this.profileRole,
              isHTML: true
            }
          ]
        }
      ];
      const companies = get(this.editing, "companiesData", []);

      if (this.$isCurrentUserAdmin && companies.length) {
        const agencyInformation = this.$isCurrentUserAdmin
          ? [
              {
                key: "Agency Code",
                value: get(this.editing, "agentCode", "")
              },
              {
                key: "Agency Name",
                value: get(this.editing, "agencyData.agencyName", "")
              }
            ]
          : [];
        const companyNames = companies.map(
          (company: any) => company.companyName
        );
        return {
          sections: [
            ...personalInformationSection,
            {
              sectionTitle: "Agency Information",
              showSection: get(this?.editing, "agencyData.agencyName", ""),
              showDivider: false,
              sectionColumnSize: 4,
              information: [...agencyInformation]
            },
            {
              sectionTitle: "Companies Information",
              showSection: this.$isCurrentUserAdmin,
              showDivider: false,
              information: [
                {
                  key: "Companies",
                  value: companyNames.join(", ")
                }
              ]
            },
            {
              sectionTitle: "License Information",
              showSection: this.agentLicense,
              showDivider: false,
              sectionColumnSize: 4,
              information: [
                {
                  key: "License Number",
                  value: this.agentLicense
                },
                {
                  key: "License Number Expiration Date",
                  value: this.editing?.licenseNumberExpirationDate
                    ? formatDate(this.editing?.licenseNumberExpirationDate)
                    : ""
                }
              ]
            }
          ]
        };
      }
      return {
        sections: [...personalInformationSection]
      };
    }
  }
});
