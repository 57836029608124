import { format as formatTz, utcToZonedTime } from "date-fns-tz";
import {
  format,
  addHours,
  startOfDay,
  subDays,
  addDays,
  parseISO
} from "date-fns";
import { getDateInDifferentTimeZone, getTimeZone } from ".";
import { LEGACY_PHP_API_DATE_FORMAT } from "./constants";

export function getPHPAPIFormattedDate(date: any) {
  return date && date instanceof Date
    ? format(date, LEGACY_PHP_API_DATE_FORMAT)
    : "";
}

export function formatDate(d: any, includeTime: boolean = false): string {
  const formatString = "MM/dd/yyyy";

  return format(
    getDateInDifferentTimeZone(new Date(d), getTimeZone()),
    includeTime ? `${formatString} kk:mm` : formatString
  );
}

export function ensureIsDate(maybeDate: string | number | Date): Date {
  // If it's already a Date object, return it
  if (maybeDate instanceof Date) {
    return maybeDate;
  }

  // If it's a string, attempt parsing it
  if (typeof maybeDate === "string") {
    // Parse ISO 8601 date strings
    const isoDate = parseISO(maybeDate);
    if (isoDate instanceof Date && !isNaN(isoDate.getTime())) {
      return isoDate;
    }

    // Handle common date string formats
    if (maybeDate.split("/").length > 1) {
      return new Date(maybeDate); // Handle string in MM/DD/YYYY or similar format
    }

    // Handle ISO-like format or other valid date string formats
    return new Date(maybeDate.replace(/-/g, "/")); // e.g. YYYY-MM-DD -> YYYY/MM/DD
  }

  // If it's a number, assume it's a timestamp
  if (typeof maybeDate === "number") {
    return new Date(maybeDate); // Timestamp to Date
  }

  // If it's neither, return an invalid date
  return new Date(NaN);
}

type DayRelativeInfoResult = {
  date: string;
  dateDay: string;
  dateDate: string;
  dateStartOfBusiness_utc: Date;
  dateEndOfBusiness_utc: Date;
  localized_current_day: number;
  dateMonth: number;
  dateHours: number;
  dateHours24: number;
  dateMinutes: number;
  localized_current_date: string;
  localized_current_date_full: string;
  localized_current_z: string;
  dateAmPm: string;
  nextDayDay: string;
  nextDayDate: string;
  nextDayStartOfBusiness_utc: Date;
  nextDayEndOfBusiness_utc: Date;
  prevDayDay: string;
  prevDayDate: string;
  prevDays: string[];
  prevDates: string[];
  nextDays: string[];
  nextDates: string[];
};

export function getDayRelativeInfo(
  date: string | Date | number = new Date(),
  type: keyof DayRelativeInfoResult | null = null // type parameter, can be a key of the result or null
): DayRelativeInfoResult | DayRelativeInfoResult[keyof DayRelativeInfoResult] {
  // Return type is either the full result or the specific field value
  const timezone = getTimeZone();
  date = ensureIsDate(date);

  // Format function to handle date formatting in the given timezone
  const formatDate = (date: Date, formatString: string) =>
    formatTz(utcToZonedTime(date, timezone), formatString);

  const result: DayRelativeInfoResult = {
    date: formatDate(date, "yyyy-MM-dd"),
    dateDay: formatTz(utcToZonedTime(date, timezone), "eeee"),
    dateDate: formatDate(date, "M/d/yyyy"),
    dateStartOfBusiness_utc: addHours(
      startOfDay(utcToZonedTime(date, timezone)),
      8
    ), // 8 am central
    dateEndOfBusiness_utc: addHours(
      startOfDay(utcToZonedTime(date, timezone)),
      18
    ), // 7 pm central

    localized_current_day: parseInt(
      formatTz(utcToZonedTime(date, timezone), "d")
    ),
    dateMonth: parseInt(formatTz(utcToZonedTime(date, timezone), "M")),
    dateHours: parseInt(formatTz(utcToZonedTime(date, timezone), "h")),
    dateHours24: parseInt(formatTz(utcToZonedTime(date, timezone), "H")),
    dateMinutes: parseInt(formatTz(utcToZonedTime(date, timezone), "mm")),
    localized_current_date: formatTz(utcToZonedTime(date, timezone), "M/d"),
    localized_current_date_full: formatTz(
      utcToZonedTime(date, timezone),
      "M/d/yyyy"
    ),
    localized_current_z: formatTz(utcToZonedTime(date, timezone), "z"), // TODO: Not always accurate, reads UTC
    dateAmPm: formatTz(utcToZonedTime(date, timezone), "aaa"),

    nextDayDay: formatTz(utcToZonedTime(addDays(date, 1), timezone), "eeee"),
    nextDayDate: formatTz(
      utcToZonedTime(addDays(date, 1), timezone),
      "M/d/yyyy"
    ),
    nextDayStartOfBusiness_utc: addHours(startOfDay(addDays(date, 1)), 8), // 8 am central
    nextDayEndOfBusiness_utc: addHours(startOfDay(addDays(date, 1)), 18), // 7 pm central

    prevDayDay: formatTz(utcToZonedTime(subDays(date, 1), timezone), "eeee"),
    prevDayDate: formatTz(
      utcToZonedTime(subDays(date, 1), timezone),
      "M/d/yyyy"
    ),

    prevDays: [
      formatTz(utcToZonedTime(subDays(date, 1), timezone), "eeee"),
      formatTz(utcToZonedTime(subDays(date, 2), timezone), "eeee"),
      formatTz(utcToZonedTime(subDays(date, 3), timezone), "eeee"),
      formatTz(utcToZonedTime(subDays(date, 4), timezone), "eeee"),
      formatTz(utcToZonedTime(subDays(date, 5), timezone), "eeee"),
      formatTz(utcToZonedTime(subDays(date, 6), timezone), "eeee")
    ],

    prevDates: [
      formatTz(utcToZonedTime(subDays(date, 1), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(subDays(date, 2), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(subDays(date, 3), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(subDays(date, 4), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(subDays(date, 5), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(subDays(date, 6), timezone), "M/d/yyyy")
    ],

    nextDays: [
      formatTz(utcToZonedTime(addDays(date, 1), timezone), "eeee"),
      formatTz(utcToZonedTime(addDays(date, 2), timezone), "eeee"),
      formatTz(utcToZonedTime(addDays(date, 3), timezone), "eeee"),
      formatTz(utcToZonedTime(addDays(date, 4), timezone), "eeee"),
      formatTz(utcToZonedTime(addDays(date, 5), timezone), "eeee"),
      formatTz(utcToZonedTime(addDays(date, 6), timezone), "eeee")
    ],

    nextDates: [
      formatTz(utcToZonedTime(addDays(date, 1), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(addDays(date, 2), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(addDays(date, 3), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(addDays(date, 4), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(addDays(date, 5), timezone), "M/d/yyyy"),
      formatTz(utcToZonedTime(addDays(date, 6), timezone), "M/d/yyyy")
    ]
  };

  // If 'type' is provided, return the specific field. Otherwise, return the entire result.
  return type ? result[type] : result;
}
