
import PolicyInfo from "../QuickPay/PolicyInfo.vue";
import { Tabs, TabPane } from "element-ui";
import {
  quickpayMapActions,
  quickpayMapMutations
} from "@/store/modules/quickpay";
import { get } from "lodash";
import DataView from "@/components/DataView/DataView.vue";
import { makeBlobUrlFromBase64 } from "@/helpers/downloadLossForm";
import VueWithMixins from "@/helpers/mixins";
import QuickPayMixin from "../QuickPay/QuickPayMixin";
import { SET_STATE } from "@/store/mutation-types";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

export default VueWithMixins(QuickPayMixin).extend({
  components: {
    PolicyInfo,
    "el-tabs": Tabs,
    "el-tab-pane": TabPane,
    DataView,
    CustomAlert
  },
  data(): {
    loadingText: string;
    activeTabName: "inquiry" | "document";
    tempEmergency: false;
    modalName: "policySignModal";
    documentSigned: boolean;
    insuredEmail: string;
    document: {
      file: string;
      insuredEmail: string;
    };
  } {
    return {
      loadingText: "",
      activeTabName: "inquiry",
      tempEmergency: false,
      modalName: "policySignModal",
      documentSigned: false,
      insuredEmail: "",
      document: {
        file: "",
        insuredEmail: ""
      }
    };
  },
  mounted() {
    this.checkEmergency();
    const quickpayMain = document.querySelector(".policy-sign--main") as any;
    if (quickpayMain.parentNode)
      quickpayMain.parentNode.classList.remove("wrap");
  },
  methods: {
    handleShowModal($event: any) {
      this.showModal({ ...$event, modalName: "policySignModal" });
    },
    ...quickpayMapActions(["savePolicySignature", "readPolicyPDF"]),
    ...quickpayMapMutations({
      setState: SET_STATE
    }),
    resetResponse() {
      this.document.file = "";
    },
    async handleReadFile() {
      await this.downloadEmailSignedDocument(this.policyInfo, "read");
      this.resetResponse();
    },
    async handleFileSigned() {
      await this.downloadEmailSignedDocument(this.policyInfo, "sign");
      this.resetResponse();
    },
    async handleEmailFile() {
      await this.downloadEmailSignedDocument(this.policyInfo, "email");
      this.resetResponse();
    },
    closeModal() {
      this.$modal.hide("policySignModal");
    },
    gotoNext(data: Record<string, any>) {
      if (this.policyInfo?.policySigned) {
        this.$appNotify({
          title: "Warning",
          message: `Signed form on file for policy ${this.policyInfo.policyPrefix} ${this.policyInfo.policyNumber}`,
          type: "warning"
        });
      }
      this.insuredEmail = data.insuredEmail;
      this.activeTabName = "document";
    },

    async downloadEmailSignedDocument(
      policyInfo: Record<string, any>,
      action: "download" | "email" | "sign" | "read"
    ) {
      try {
        const { Agent: agency, policyPrefix, policyNumber } = policyInfo;
        const data = {
          agency,
          insuredName: get(this.policyInfo, "name", ""),
          policyNumber,
          policyPrefix,
          action,
          insuredEmail: this.insuredEmail,
          agencyName: get(this.policyInfo, "agencyName", "")
        };
        this.loadingText = `Signing document ${policyPrefix} ${policyNumber} document `;

        if (action === "read") {
          await this.readPolicyPDF({ ...data, action });
        } else {
          this.document = await this.savePolicySignature(data);
          const { file } = this.document;
          if (file) {
            let message = "Document signed";
            if (action === "sign") {
              message = "File signed";
            } else if (action === "email") {
              message = `Document has been emailed to ${data.insuredEmail}.`;
            } else if (action === "download") {
              message = "File downloaded";
            }

            this.documentSigned = true;
            this.$appNotifySuccess(message);
          } else {
            this.$appNotifyError(`${action} Failed`);
          }
        }
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.$appNotifyError(error.message);
      } finally {
        this.loadingText = "";
      }
    },
    async handleDocumentDownload() {
      try {
        await this.downloadEmailSignedDocument(this.policyInfo, "download");
        const element = document.createElement("a");
        if (this.document.file) {
          element.href = makeBlobUrlFromBase64(this.document.file);
          element.download = `${get(
            this.policyInfo,
            "name"
          )}_SIGNED_POLICY_FORM`;
          element.target = "_blank";
          element.click();
        }
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.$appNotifyError(error.message);
      } finally {
        this.resetResponse();
      }
    },
    goHome() {
      this.$router.push("/").catch(() => {});
    }
  },
  computed: {
    insuredAddress(): any {
      const zip = get(this.policyInfo, "zip", {});
      let address = get(this.policyInfo, "printAddress1", "");
      for (const key in zip) {
        address += ` ${zip[key]} `;
      }
      return address;
    },
    showSubscriberEmailButton() {
      if (!this.document?.insuredEmail && !this.policyInfo.insuredEmail) {
        return true;
      }
      return false;
    },
    getData(): any {
      return {
        sections: [
          {
            sectionTitle: "",
            showDivider: true,
            showSection: true,
            sectionColumnSize: "2",
            information: [
              {
                informationColumnSize: "1",
                key: "Insured Name",
                value: get(this.policyInfo, "name", "")
              },
              {
                informationColumnSize: "1",
                key: "Policy",
                value: get(this.policyInfo, "policy")
              },
              {
                informationColumnSize: "1",
                key: "Insured Address",
                value: this.insuredAddress
              },
              {
                informationColumnSize: "1",
                key: "Agency",
                value: get(this.policyInfo, "agencyName", "").toUpperCase()
              }
            ]
          }
        ]
      };
    },
    disableTab(): any {
      return this.policyInfo && !this.policyInfo.name;
    }
  },
  destroyed() {
    this.setState({ key: "policyInfo", value: {} });
  }
});
